import type { SavedTile, SavedTilePosition, SavedTileSize } from 'api/workspaceService/model';
import { COLUMN_WIDTH } from 'styles/constants';
import type { Columns, GridState } from '../../gridLayoutModels';

export const restoreGridItem =
  (tileId: string, savedTile: SavedTile) =>
  ({ gridLayout: { columns, gridItemPositions, gridItemSizes } }: GridState) => ({
    gridLayout: {
      columns: addLoci(columns, tileId, savedTile.position, savedTile.size),
      gridItemPositions: {
        ...gridItemPositions,
        [tileId]: { top: savedTile.position.top, left: savedTile.position.colPosition * COLUMN_WIDTH },
      },
      gridItemSizes: {
        ...gridItemSizes,
        [tileId]: { height: savedTile.size.height, width: savedTile.size.colSize * COLUMN_WIDTH },
      },
    },
  });

function addLoci(
  columns: Columns,
  gridItemId: string,
  { top, colPosition }: SavedTilePosition,
  { height, colSize }: SavedTileSize,
) {
  let newColumns = [...columns];

  const columnIndexes = Array.from(Array(colSize), (_item, index) => index + colPosition);
  const maxIndex = Math.max(...columnIndexes);

  if (newColumns.length <= maxIndex) {
    const missingColNumber = maxIndex + 1 - newColumns.length;

    const missingColumns = missingColNumber > 0 ? Array.from(Array(missingColNumber), () => []) : [];

    newColumns = newColumns.concat(missingColumns);
  }

  for (const index of columnIndexes) {
    newColumns[index] = [...newColumns[index], { top, bottom: top + height, gridItemId }].sort(
      ({ top: a }, { top: b }) => a - b,
    );
  }

  return newColumns;
}
