import { assertUnreachable } from '@sgme/fp';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { InstrumentChoice } from 'state/referenceData/referenceDataModel';
import type { Selectors } from 'state/selectors';
import type { MapDispatchToPropsHOF, MapStateToPropsFactoryHOF } from 'typings/redux-utils';
import { assertUnhandled } from 'utils/error';

export interface InstrumentChooserConnectOwnProps {
  quoteId: string;
}

export interface InstrumentChooserConnectStateProps {
  instrument: InstrumentChoice;
  availableInstruments: readonly InstrumentChoice[];
}

export interface InstrumentChooserConnectDispatchProps {
  onInstrumentChange(instrument: InstrumentChoice): void;
}

// you can whitelist selectors after implementation
export type InstrumentChooserConnectSelectors = Selectors;

export const mapStateToPropsInstrumentChooser: MapStateToPropsFactoryHOF<
  InstrumentChooserConnectStateProps,
  InstrumentChooserConnectOwnProps,
  AppState,
  InstrumentChooserConnectSelectors
> = (sl) => () => {
  return (state, { quoteId }) => {
    const instrument = getInstrumentChoiceForTileWith(state, sl, quoteId);

    const availableInstruments = sl.getAuthorizedInstruments(state);

    return {
      availableInstruments,
      instrument,
    };
  };
};

const getInstrumentChoiceForTileWith = (state: AppState, sl: Selectors, quoteId: string): InstrumentChoice => {
  const { instrument } = sl.getTileState(state, quoteId);

  switch (instrument) {
    case 'Accumulator': {
      const productName = sl.getAccumulatorProductName(state, quoteId);

      switch (productName) {
        case 'FxTargetAccumulator':
          return 'TargetAccumulator';
        case 'FxForwardAccumulator':
          return 'ForwardAccumulator';
        default:
          assertUnreachable(productName, 'this product name is not handled');
      }
      break;
    }

    case 'Cash':
    case 'Swap':
    case 'AmericanForward':
    case 'Option':
    case 'SmartRfs':
      return instrument;

    case 'Order':
    case 'BlotterOrder':
    case 'Bulk':
      return assertUnhandled('orders and bulk should not have instrument chooser', instrument);
  }
};

// you can whitelist action creators after implementation
export type InstrumentChooserConnectActionCreators = ActionCreators;

export const mapDispatchToPropsInstrumentChooser: MapDispatchToPropsHOF<
  InstrumentChooserConnectDispatchProps,
  InstrumentChooserConnectOwnProps,
  InstrumentChooserConnectActionCreators
> =
  (ac) =>
  (dispatch, { quoteId }) => ({
    onInstrumentChange: (instrument) => {
      dispatch(ac.tileInstrumentChangedThunk(quoteId, instrument));
    },
  });
