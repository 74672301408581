import { unminifyError } from '@sgme/stacktrace';
import {
  type OnActionNotifierCallback,
  actionCreatorTypeExtractor,
} from 'bootstrap/reduxMiddlewares/actionNotificationMiddleware';
import { crashLogger, logger } from 'logging/logger';
import type { AnyAction } from 'redux';
import type { Action } from 'state/actions';
import { type ClientworkspaceTileClientErrorChanged, changeTileClientError } from 'state/clientWorkspace';
import { type AppCrashedAction, createAppCrashedAction } from 'state/globalError';
import type { AppState } from 'state/model';
import { extract } from 'utils/object';

const sanitizeLoggedAction = (action: Action): AnyAction => {
  if (action.type === 'BLOTTER_INTRADAY_TRADES_RECEIVED') {
    const { trades, ...rest } = action;
    return {
      ...rest,
      trades: trades.map(extract('id')),
    };
  }
  return action;
};

export const logActionCallback: OnActionNotifierCallback<AppState> = async (action: Action) => {
  if (isErrorAction(action)) {
    const exposedError = await unminifyError(action?.error);
    const {
      type,
      error: { message, stack },
    } = { ...action, error: exposedError };
    crashLogger.logError(
      '[APP_CRASHED][Error] action: {type_s}, message: {message_s}, stack: {stack_s}',
      type,
      message,
      stack,
    );
    return;
  }

  if (isChangeTileClientError(action)) {
    logger.logError('error action {action}', JSON.stringify(action));
    return;
  }

  logger.logInformation('action {action}', JSON.stringify(sanitizeLoggedAction(action)));
  return;
};

function isErrorAction(action: Action): action is AppCrashedAction {
  return action.type === actionCreatorTypeExtractor(createAppCrashedAction);
}

function isChangeTileClientError(action: Action): action is ClientworkspaceTileClientErrorChanged {
  return action.type === actionCreatorTypeExtractor(changeTileClientError);
}
