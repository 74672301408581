import type React from 'react';

type SwitchChildrenProps<K extends string, S> = Record<K, S>;

export type ChildrenMap<S extends string> = Record<S, React.ReactNode>;

// TODO: remove with very bad function to avoid so many rerender. no value added, just more complexity
export function createSwitchChildren<K extends Exclude<string, 'children' | 'key' | 'ref'>, S extends string>(
  key: K,
  displayName: string,
  childrenMap: ChildrenMap<S>,
) {
  function SwitchChildren(props: SwitchChildrenProps<K, S>) {
    const request = props[key];
    const child = childrenMap[request];

    return child as React.ReactElement<any>;
  }

  SwitchChildren.displayName = displayName;

  return SwitchChildren;
}

export function defaultSwitch<K extends string, T>(keys: readonly K[], obj: T): Record<K, T> {
  const ret = {} as Record<K, T>;

  for (const key of keys) {
    ret[key] = obj;
  }

  return ret;
}
