import { isDefined, isEmpty } from '@sgme/fp';
import { logger } from 'logging/logger';
import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import {
  getCurrentPriceField,
  getPriceWith,
  isBlotterOrderLimitInputs,
  isOrderBlotterLimitEntry,
} from 'state/fxOrders/utils';
import { validationPartialReducer } from 'state/share/validationReducer';
import type { Collection } from 'typings/utils';
import type { GetKeysExtending } from 'utils/object';
import { strictEntries } from 'utils/object/entries';
import { addKey, patchValueByProperty, removeKey, removeKeys, updateKey } from 'utils/stateMap';
import { getChildrenIdsInBlotterData } from '../../../epics/blotter/utils';
import type { BlotterEditedOrderPropertiesChanged } from '../action';
import {
  type BlotterEntry,
  type BlotterOrderAlgoInputs,
  type BlotterOrderAlgoValues,
  type BlotterOrderAllInputsKeys,
  type BlotterOrderCommonInputs,
  type BlotterOrderCommonValues,
  type BlotterOrderInputs,
  type BlotterOrderLimitInputs,
  type BlotterOrderLimitValues,
  type BlotterOrderValues,
  isOrderBlotterEntry,
  type TradeBlotterEntry,
} from '../blotterEntryModel';
import type { BlotterData } from '../blotterModel';
import { integrateTrades } from './integrateTrades';

const orderValidationReducer = validationPartialReducer<'BlotterOrder', BlotterEntry>('BlotterOrder');

export const intradayReducer: Reducer<BlotterData> = (blotterData: BlotterData = {}, action: Action): BlotterData => {
  switch (action.type) {
    case 'BLOTTER_ENTRY_RECEIVED':
      return updateTradeState(blotterData, action.entry);
    case 'BLOTTER_INTRADAY_TRADES_RECEIVED':
      return integrateTrades(blotterData, action.trades);
    case 'BLOTTER_ORDER_LIST_RECEIVED':
      return isEmpty(action.orders)
        ? blotterData
        : action.orders.reduce(
            (acc, val) => {
              acc[val.id] = val;
              return acc;
            },
            { ...blotterData },
          );
    case 'ORDER_SUBMISSION_SUCCESS':
      return action.order === undefined || action.orderId === null || blotterData[action.orderId] !== undefined
        ? blotterData
        : addKey(blotterData, action.orderId, {
            values: { ...action.order, updateTime: null } as any,
            inputs: {},
            errors: {},
            warnings: {},
            id: action.orderId,
            instrument: 'Order',
            receivedDate: action.receivedDate,
            mode: 'Display',
            modeStatus: 'Idle',
          });
    case 'ESP_STREAM_RECONNECTED':
      return updateKey<BlotterEntry>(blotterData, action.tileId, () => ({
        currentEspStreamId: action.streamKey,
      }));
    case 'ESP_STREAM_TILE_UNSUBSCRIBE':
      return updateKey<BlotterEntry>(blotterData, action.tileId, () => ({
        currentEspStreamId: undefined,
      }));
    case 'BLOTTER_ORDER_TOGGLE_MODE':
      return updateKey<BlotterEntry>(blotterData, action.orderId, () => ({
        mode: action.mode,
        modeStatus: 'Idle',
      }));
    case 'BLOTTER_ORDER_MODIFY_EPIC':
      return updateKey<BlotterEntry>(blotterData, action.orderId, () => ({
        mode: 'Save',
        modeStatus: 'Pending',
      }));
    case 'BLOTTER_ORDER_CANCELLATION_EPIC':
      return updateKey<BlotterEntry>(blotterData, action.orderId, () => ({
        mode: 'Cancel',
        modeStatus: 'Pending',
      }));
    case 'BLOTTER_ORDER_OPEN_TILE':
      return updateKey<BlotterEntry>(blotterData, action.orderId, () => ({
        mode: action.mode,
      }));
    case 'ORDER_CANCELLATION_SUCCESS':
      return updateKey<BlotterEntry>(blotterData, action.quoteId, () => ({
        mode: 'Display',
        modeStatus: 'Idle',
      }));
    case 'ORDER_CANCELLATION_FAILED':
      return updateKey<BlotterEntry>(blotterData, action.quoteId, () => ({
        modeStatus: 'Error',
      }));
    case 'BLOTTER_ORDER_UPDATE_FAILED':
      return updateKey<BlotterEntry>(blotterData, action.orderId, () =>
        action.isValidationError
          ? { mode: 'Edit', modeStatus: 'Idle' }
          : { modeStatus: 'Error', errorCode: action.errorCode },
      );

    case 'ORDER_ERROR_DISMISSED':
      return updateKey<BlotterEntry>(blotterData, action.quoteId, (trade) =>
        isOrderBlotterEntry(trade)
          ? {
              modeStatus: 'Idle',
            }
          : null,
      );
    case 'FIELD_TOOLTIP_SEEN':
      return action.instrument !== 'BlotterOrder'
        ? blotterData
        : updateKey<BlotterEntry>(blotterData, action.quoteId, ({ errors, warnings }) => ({
            // todo-5010 check typing
            // @ts-ignore
            errors: updateKey(errors, action.field, () => ({ userNotified: true })),
            // todo-5010 check typing
            // @ts-ignore
            warnings: updateKey(warnings, action.field, () => ({ userNotified: true })),
          }));

    case 'BLOTTER_ORDER_RESET_PROPERTY':
      return updateKey<BlotterEntry>(blotterData, action.orderId, (trade) =>
        isOrderBlotterEntry(trade)
          ? {
              // TODO ABO, SGEFX-5010: check type
              // @ts-ignore
              inputs: removeKey(trade.inputs, action.field),
              // @ts-ignore
              warnings: removeKey(trade.warnings, action.field),
              // @ts-ignore
              errors: removeKeys(trade.errors, [action.field, 'limitPrice']),
            }
          : null,
      );
    case 'BLOTTER_ORDER_ERRORS_RECEIVED':
      return updateKey<BlotterEntry>(blotterData, action.orderId, (trade) =>
        isOrderBlotterEntry(trade) ? patchValueByProperty(trade, 'errors', () => action.errors) : null,
      );
    case 'BLOTTER_ORDER_UPDATE_SUCCESS':
      return updateKey(
        blotterData,
        action.orderId,
        (trade) =>
          isOrderBlotterEntry(trade)
            ? patchValueByProperty(trade, 'values', () => mapOrderInputsToValues(trade.inputs))
            : null,
        (trade) => patchValueByProperty(trade, 'inputs', () => ({})),
        () => ({ mode: 'Display' as const, modeStatus: 'Idle' as const }),
      );
    case 'BLOTTER_ORDER_UPDATE_STILL_PENDING':
      return updateKey<BlotterEntry>(blotterData, action.orderId, (trade) =>
        isOrderBlotterEntry(trade) ? { modeStatus: 'StillPending' } : null,
      );
    case 'BLOTTER_ORDER_PAUSE_EPIC':
    case 'BLOTTER_ORDER_RESUME_EPIC':
      return updateKey<BlotterEntry>(blotterData, action.orderId, (trade) =>
        isOrderBlotterEntry(trade) ? { togglePausePending: true } : null,
      );
    case 'BLOTTER_ORDER_RESUME_SUCCESS':
    case 'BLOTTER_ORDER_RESUME_FAILURE':
    case 'BLOTTER_ORDER_PAUSE_SUCCESS':
    case 'BLOTTER_ORDER_PAUSE_FAILURE':
      return updateKey<BlotterEntry>(blotterData, action.orderId, (trade) =>
        isOrderBlotterEntry(trade) ? { togglePausePending: false } : null,
      );
    case 'BLOTTER_ORDER_FILL_EPIC':
      return updateKey<BlotterEntry>(blotterData, action.orderId, (trade) =>
        isOrderBlotterEntry(trade) ? { fillNowPending: true } : null,
      );
    case 'BLOTTER_ORDER_FILL_SUCCESS':
    case 'BLOTTER_ORDER_FILL_FAILURE':
      return updateKey<BlotterEntry>(blotterData, action.orderId, (trade) =>
        isOrderBlotterEntry(trade) ? { fillNowPending: false } : null,
      );
    case 'ESP_TILE_STREAM_ID_AND_REFCOUNT_UPDATED':
      return updateKey<BlotterEntry>(blotterData, action.tileId, (trade) =>
        isOrderBlotterEntry(trade) ? { currentEspStreamId: action.streamId } : null,
      );
    case 'BLOTTER_FETCH_INTRADAY_TRADES_STARTED':
      return Object.entries(blotterData).reduce(
        (acc, [key, entry]) => {
          if (action.ordersToKeep?.includes(key)) {
            acc[key] = entry;
          }
          return acc;
        },
        {} as Collection<BlotterEntry>,
      );
  }
  return orderValidationReducer(blotterData, action);
};

const updateTradeState = (trades: BlotterData, newTrade: BlotterEntry) => {
  const legacyTrade = trades[newTrade.id];

  if (legacyTrade === undefined) {
    if (newTrade.instrument === 'Order') {
      return addKey(trades, newTrade.id, newTrade);
    }

    if (newTrade.strategyReference) {
      const strategyReferenceTrade = trades[newTrade.strategyReference] as TradeBlotterEntry;

      if (strategyReferenceTrade) {
        const shouldUpdateStrategyReferenceTrade =
          !strategyReferenceTrade.childrenIds || !strategyReferenceTrade.childrenIds.includes(newTrade.id);

        if (shouldUpdateStrategyReferenceTrade) {
          const updatedTrades = updateKey(trades, strategyReferenceTrade.id, () => ({
            ...strategyReferenceTrade,
            ...{
              childrenIds: [...(strategyReferenceTrade.childrenIds ?? []), newTrade.id],
            },
          }));

          return addKey(updatedTrades, newTrade.id, newTrade);
        }
      }

      return addKey(trades, newTrade.id, newTrade);
    }

    const childrenIds = newTrade.childrenIds || getChildrenIdsInBlotterData(trades, newTrade);
    return addKey(trades, newTrade.id, { ...newTrade, ...{ childrenIds } });
  }
  if (
    legacyTrade.values.updateTime === null || // Check needed because we are creating orders ex-nihilo on ORDER_SUBMISSION_SUCCESS and null < '' === false
    legacyTrade.values.updateTime < newTrade.values.updateTime
  ) {
    // @ts-ignore
    return updateKey(trades, newTrade.id, (existingTrade) => {
      const takeProfitmargin =
        // @ts-ignore
        existingTrade.values.product === 'TakeProfit' && existingTrade.inputs.margin
          ? // @ts-ignore
            { margin: existingTrade.inputs.margin }
          : {};

      if (existingTrade.instrument === 'Order') {
        return {
          ...newTrade,
          values: {
            ...newTrade.values,
            ...takeProfitmargin,
          },
          modeStatus: existingTrade.modeStatus,
          mode: existingTrade.mode,
        };
      }

      return newTrade;
    });
  }

  logger.logInformation('Ignoring new trade', newTrade);
    return trades;
};

type BlotterInputsWhichNeedNumberConvert =
  | InputsWhichNeedNumberConvert<BlotterOrderCommonValues, BlotterOrderCommonInputs>
  | InputsWhichNeedNumberConvert<BlotterOrderLimitValues, BlotterOrderLimitInputs>
  | InputsWhichNeedNumberConvert<BlotterOrderAlgoValues, BlotterOrderAlgoInputs>;

type InputsWhichNeedNumberConvert<V, I> = GetKeysExtending<V, number | null> & keyof I;

const inputsWhichNeedNumberConvert: readonly BlotterInputsWhichNeedNumberConvert[] = [
  'notional',
  'limitPrice',
  'customerPrice',
];

const needConvert = (input: BlotterOrderAllInputsKeys): input is BlotterInputsWhichNeedNumberConvert =>
  (inputsWhichNeedNumberConvert as readonly string[]).includes(input);

const mapOrderInputsToValues = (inputs: Partial<BlotterOrderInputs>): Partial<BlotterOrderValues> =>
  strictEntries(inputs).reduce(
    (acc, [key, value]) => {
      if (isDefined(value) && needConvert(key)) {
        acc[key as keyof typeof acc] = Number(value) as any;
      }
      return acc;
    },
    {} as Partial<BlotterOrderValues>,
  );

export function preparePricesAndMargin(action: BlotterEditedOrderPropertiesChanged, trade: BlotterEntry) {
  const currentPriceField = getCurrentPriceField(action.patch);

  const isTakeProfit = trade.values.product === 'TakeProfit';
  const hasPriceField = currentPriceField !== null;

  if (isTakeProfit && hasPriceField && isOrderBlotterLimitEntry(trade) && isBlotterOrderLimitInputs(action.patch)) {
    const margin = trade.inputs.margin ?? trade.values.margin ?? 0;
    const way = trade.values.way;
    const getPrice = getPriceWith(way, currentPriceField);

    switch (currentPriceField) {
      case 'limitPrice': {
        const limitPrice = action.patch.limitPrice ? Number(action.patch.limitPrice) : 0;

        return {
          customerPrice: getPrice(limitPrice, margin),
        };
      }
      case 'customerPrice': {
        const customerPrice = action.patch.customerPrice ? Number(action.patch.customerPrice) : 0;

        return {
          limitPrice: getPrice(customerPrice, margin),
        };
      }
      case 'margin': {
        const customerPriceInput = trade.inputs.customerPrice ?? trade.values.customerPrice;
        const customerPriceValue = customerPriceInput ? Number(customerPriceInput) : 0;

        return {
          limitPrice: getPrice(customerPriceValue, action.patch.margin ?? 0),
        };
      }
    }
  }

  return {};
}
