import type { BlotterEntry, OrderBlotterEntry } from './blotterEntryModel';
import type { ImmutableCollection } from 'typings/utils';
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import type { OrderValidationReceivedDetails } from 'state/fxOrders/fxOrdersActions';
import type { BlotterOrderValidationDetail } from 'epics/blotter/utils';

export const blotterTabs = ['cash', 'option', 'order', 'accumulator'] as const;
export type BlotterTab = (typeof blotterTabs)[number];

export type BlotterCountByTab = { [key in BlotterTab]: number };

interface BlotterColSort {
  colId: string;
  sort?: 'asc' | 'desc' | null;
}
export type BlotterSortModel = readonly BlotterColSort[];

export const detailsPanelConfirmationModes = [
  'SaveConfirm',
  'CancelConfirm',
  'PauseConfirm',
  'ResumeConfirm',
  'FillConfirm',
] as const;

export const detailsPanelSuccessModes = [
  'SaveSuccess',
  'CancelSuccess',
  'PauseSuccess',
  'ResumeSuccess',
  'FillSuccess',
] as const;

export const detailsPanelModes = [
  'Display',
  'Edit',
  'SavePending',
  'SaveError',
  'SaveTimeout',
  'CancelPending',
  'CancelError',
  'CancelTimeout',
  'RejectedOrder',
  'InErrorOrder',
  'PausePending',
  'PauseError',
  'PauseTimeout',
  'ResumePending',
  'ResumeError',
  'ResumeTimeout',
  'FillPending',
  'FillError',
  'FillTimeout',
  ...detailsPanelConfirmationModes,
  ...detailsPanelSuccessModes,
] as const;

export type DetailsPanelMode = (typeof detailsPanelModes)[number];
export const openTileAction = 'openTile';

export const isSameBlotterSortModel = (columnsState: BlotterSortModel, rightModel: BlotterSortModel): boolean => {
  if (columnsState.length !== rightModel.length) {
    return false;
  }
  for (let index = 0; index < columnsState.length; index++) {
    if (
      !Object.is(columnsState[index].colId, rightModel[index].colId) ||
      !Object.is(columnsState[index].sort, rightModel[index].sort)
    ) {
      return false;
    }
  }
  return true;
};

export interface BlotterRowMetadata {
  isExpanded: boolean;
}
export interface BlotterTabMetadata {
  autoColumState: { width: number };
  columnsState: FxColumnState[];
  sortModel: BlotterSortModel;
  filterModel?: any;
  rowModel: { [rowId: string]: BlotterRowMetadata };
}

export interface BlotterDetailsPanel {
  selectedId: string | null;
  childrenIds: readonly string[] | null;
  mode: DetailsPanelMode | null;
  saveError?: string;
  hasUnexpectedError: boolean;
  validationResponse?: OrderValidationReceivedDetails<BlotterOrderValidationDetail> | null;
}

export type IBlotterErrorType = 'tableCrash' | 'live' | 'historical' | 'network' | 'historicalOrder';

export const blotterModes = ['historical', 'intraday'] as const;
export type BlotterMode = (typeof blotterModes)[number];

export const dateRanges = ['yesterday', 'week', 'lastWeek', 'month', 'lastMonth', 'customRange'] as const;
export type DateRange = (typeof dateRanges)[number];
type PredefinedDateRange = Exclude<DateRange, 'customRange'>;

const opts = { weekStartsOn: 1 } as const;
const now = new Date();
const yesterday = addDays(now, -1);
const lastWeekDay = isSameWeek(now, yesterday, opts) ? yesterday : addDays(yesterday, -2); // since yesterday is Sunday, get the previous Friday
const sameDayPrevWeek = addDays(now, -7);
const sameDayPrevMonth = addMonths(now, -1);
const mondayOfWeek = (date: Date) => startOfWeek(date, opts);
const sundayOfWeek = (date: Date) => endOfWeek(date, opts);

export const datesByDateRange: Record<PredefinedDateRange, [startDate: Date, endDate: Date]> = {
  yesterday: [startOfDay(lastWeekDay), endOfDay(lastWeekDay)],
  week: [mondayOfWeek(now), endOfDay(now)],
  lastWeek: [mondayOfWeek(sameDayPrevWeek), sundayOfWeek(sameDayPrevWeek)],
  month: [startOfMonth(now), endOfDay(now)],
  lastMonth: [startOfMonth(sameDayPrevMonth), endOfMonth(sameDayPrevMonth)],
};

type BlotterIntraday = {}

export type BlotterHistoricalError = 'tooManyResults' | 'serverError' | 'timeout' | null;

interface BlotterHistoricalErrorData {
  error: BlotterHistoricalError;
  errorDetails?: string;
}

interface BlotterHistorical extends BlotterHistoricalErrorData {
  dateRange: PredefinedDateRange;
  startDate: Date;
  endDate: Date;
}

interface BlotterHistoricalCustom extends BlotterHistoricalErrorData {
  dateRange: 'customRange';
  startDate: Date | null;
  endDate: Date | null;
}

export interface BlotterModeMetadata {
  mode: 'intraday' | 'historical';
  intraday?: BlotterIntraday;
  historical?: BlotterHistorical | BlotterHistoricalCustom;
}

export type CantReopenReason =
  | 'not_a_trade'
  | 'client_missing'
  | 'unexpected_product'
  | 'unauthorized_product'
  | 'invalid_option_type'
  | 'invalid_leg'
  | 'unknown_hedge_type'
  | 'unhandled_product'
  | 'invalid_option_leg_type';

export interface IBlotterMetadata {
  isOpen: boolean;
  fetching: BlotterMode | null;
  canRefetch: boolean;
  errors: readonly IBlotterErrorType[];
  activeTab: BlotterTab;
  panelHeight: number;
  enableShowMyMainDeals: boolean;
  orderRejectedId: string | null;
  showSaveErrorDialogBox: boolean;
  displayedErrorId?: string;
  cashTab: BlotterTabMetadata;
  optionTab: BlotterTabMetadata;
  orderTab: BlotterTabMetadata;
  accumulatorTab: BlotterTabMetadata;
  detailsPanel: BlotterDetailsPanel;
  cantReopenReason: CantReopenReason | null;
}

export type BlotterMetadata = IBlotterMetadata & BlotterModeMetadata;

export type BlotterDataIds = readonly string[];
export type BlotterData = ImmutableCollection<BlotterEntry>;
export type BlotterEditOrderData = ImmutableCollection<OrderBlotterEntry>;

export interface IBlotterState {
  intraday: BlotterData;
  historical: BlotterData;
  metadata: BlotterMetadata;
  editedOrders: BlotterEditOrderData;
}

export type BlotterState = Readonly<IBlotterState>;

const columnIdArray = [
  'id',
  'account',
  'defaultAction',
  'orderAction',
  'amountDone',
  'currencyPair',
  'date',
  'expiry',
  'farDate',
  'farRate',
  'gtc',
  'inputDate',
  'nearDate',
  'nearRate',
  'notional',
  'notionalCurrency',
  'dealtAmount',
  'dealtCurrency',
  'farDealtAmount',
  'contraAmount',
  'contraCurrency',
  'farContraAmount',
  'limitPrice',
  'customerPrice',
  'points',
  'portfolio',
  'premium',
  'strategyReference',
  'electronicReference',
  'electronicAccount',
  'product',
  'salesFullName',
  'spot',
  'spotDate',
  'status',
  'strike',
  'forwardRate',
  'time',
  'updateTime',
  'venue',
  'way',
  'executionPrice',
  'premiumCurrency',
  'premiumSide',
  'blotterType',
  'optionType',
  'fixingFrequency',
  'settlementFrequency',
  'koConventionType',
  'koTime',
  'startTime',
  'endTime',
  'maturityDate',
  'liquidityPool',
  'clippingMode',
  'clipSize',
  'randomize',
  'swapPoints',
  'forwardPrice',
  'remainingAmount',
  'averagePrice',
  'noWorseThan',
  'spreadCapture',
  'alphaSeeker',
  'speed',
  'fixingBenchmark',
  'fixingPlace',
  'fixingTime',
  'fixingDateUtc',
  'fixingPriceType',
  'fixingMarginType',
  'marginInBps',
  'price',
  'deliveryDate',
  'deliveryType',
  'premiumPaymentDate',
  'cutOffPlace',
  'callabilityStartDate'
] as const;

export const fixingColumns = [
  'fixingBenchmark',
  'fixingPlace',
  'fixingTime',
  'fixingDateUtc',
  'fixingPriceType',
  'fixingMarginType',
  'marginInBps',
  'price',
];

export type ColumnId = (typeof columnIdArray)[number];

export interface FxColumnState {
  colId: ColumnId;
  width?: number;
  hide?: boolean;
}

export const columnsDefinition: Record<ColumnId, FxColumnState> = {
  id: { colId: 'id' },
  defaultAction: { colId: 'defaultAction', hide: false },
  account: { colId: 'account', width: 200 },
  orderAction: { colId: 'orderAction' },
  amountDone: { colId: 'amountDone' },
  currencyPair: { colId: 'currencyPair', width: 90 },
  date: { colId: 'date' },
  expiry: { colId: 'expiry', width: 100 },
  farDate: { colId: 'farDate', width: 100 },
  farRate: { colId: 'farRate', width: 100 },
  gtc: { colId: 'gtc' },
  inputDate: { colId: 'inputDate' },
  nearDate: { colId: 'nearDate', width: 100 },
  nearRate: { colId: 'nearRate', width: 100 },
  notional: { colId: 'notional', width: 150 },
  notionalCurrency: {
    colId: 'notionalCurrency',
    width: 120,
  },
  dealtAmount: { colId: 'dealtAmount', width: 150 },
  farDealtAmount: { colId: 'farDealtAmount', width: 150 },
  dealtCurrency: {
    colId: 'dealtCurrency',
    width: 120,
  },
  contraAmount: { colId: 'contraAmount', width: 150 },
  farContraAmount: { colId: 'farContraAmount', width: 150 },
  contraCurrency: {
    colId: 'contraCurrency',
    width: 120,
  },
  limitPrice: { colId: 'limitPrice' },
  customerPrice: { colId: 'customerPrice' },
  points: { colId: 'points', width: 80 },
  portfolio: { colId: 'portfolio' },
  premium: { colId: 'premium' },
  product: { colId: 'product', width: 80 },
  salesFullName: {
    colId: 'salesFullName',
    width: 140,
  },
  spot: { colId: 'spot', width: 80 },
  spotDate: { colId: 'spotDate', width: 100 },
  status: { colId: 'status', width: 120 },
  strike: { colId: 'strike', width: 120 },
  forwardRate: { colId: 'forwardRate', width: 120 },
  time: { colId: 'time', width: 100 },
  updateTime: { colId: 'updateTime', hide: true },
  venue: { colId: 'venue', width: 120 },
  way: { colId: 'way', width: 100 },
  executionPrice: { colId: 'executionPrice', width: 100 },
  premiumCurrency: { colId: 'premiumCurrency', width: 120 },
  premiumSide: { colId: 'premiumSide', width: 120 },
  optionType: { colId: 'optionType', width: 120 },
  fixingFrequency: { colId: 'fixingFrequency', width: 100 },
  settlementFrequency: { colId: 'settlementFrequency', width: 100 },
  koConventionType: { colId: 'koConventionType', width: 100 },
  koTime: { colId: 'koTime', width: 100 },
  electronicReference: { colId: 'electronicReference' },
  electronicAccount: { colId: 'electronicAccount' },
  strategyReference: { colId: 'strategyReference' },
  blotterType: { colId: 'blotterType' },
  startTime: { colId: 'startTime' },
  endTime: { colId: 'endTime' },
  maturityDate: { colId: 'maturityDate' },
  liquidityPool: { colId: 'liquidityPool' },
  clippingMode: { colId: 'clippingMode' },
  clipSize: { colId: 'clipSize' },
  randomize: { colId: 'randomize' },
  swapPoints: { colId: 'swapPoints' },
  forwardPrice: { colId: 'forwardPrice' },
  remainingAmount: { colId: 'remainingAmount' },
  averagePrice: { colId: 'averagePrice' },
  noWorseThan: { colId: 'noWorseThan' },
  spreadCapture: { colId: 'spreadCapture' },
  alphaSeeker: { colId: 'alphaSeeker' },
  speed: { colId: 'speed' },
  fixingBenchmark: { colId: 'fixingBenchmark' },
  fixingPlace: { colId: 'fixingPlace' },
  fixingTime: { colId: 'fixingTime' },
  fixingDateUtc: { colId: 'fixingDateUtc' },
  fixingPriceType: { colId: 'fixingPriceType' },
  fixingMarginType: { colId: 'fixingMarginType' },
  marginInBps: { colId: 'marginInBps' },
  price: { colId: 'price' },
  deliveryDate: { colId: 'deliveryDate' },
  deliveryType: { colId: 'deliveryType' },
  premiumPaymentDate: { colId: 'premiumPaymentDate' },
  cutOffPlace: { colId: 'cutOffPlace' },
  callabilityStartDate: {colId: 'callabilityStartDate'}
};
export const defaultBlotterSortModel: BlotterSortModel = [{ colId: 'updateTime', sort: 'desc' }];

const defaultAutoColumnState = {
  width: 100,
};

const pickColumns = (colIds: readonly ColumnId[]): FxColumnState[] => colIds.map(id => columnsDefinition[id]);

export const defaultCashTab: BlotterTabMetadata = {
  autoColumState: defaultAutoColumnState,
  columnsState: pickColumns([
    'defaultAction',
    'status',
    'date',
    'time',
    'currencyPair',
    'product',
    'dealtAmount',
    'farDealtAmount',
    'dealtCurrency',
    'contraAmount',
    'farContraAmount',
    'contraCurrency',
    'way',
    'spot',
    'spotDate',
    'nearRate',
    'nearDate',
    'farRate',
    'farDate',
    'points',
    'venue',
    'account',
    'portfolio',
    'salesFullName',
    'updateTime',
    'electronicAccount',
  ]),
  sortModel: defaultBlotterSortModel,
  rowModel: {},
};

export const defaultOptionTab: BlotterTabMetadata = {
  autoColumState: defaultAutoColumnState,
  columnsState: pickColumns([
    'defaultAction',
    'status',
    'product',
    'optionType',
    'currencyPair',
    'way',
    'dealtAmount',
    'dealtCurrency',
    'contraAmount',
    'contraCurrency',
    'strike',
    'forwardRate',
    'spot',
    'expiry',
    'premium',
    'date',
    'time',
    'venue',
    'account',
    'portfolio',
    'salesFullName',
    'updateTime',
    'premiumCurrency',
    'premiumSide',
    'electronicAccount',
    'deliveryDate',
    'deliveryType',
    'premiumPaymentDate',
    'cutOffPlace',
    'callabilityStartDate',
  ]),
  sortModel: defaultBlotterSortModel,
  rowModel: {},
};

export const defaultOrderTab: BlotterTabMetadata = {
  autoColumState: defaultAutoColumnState,
  columnsState: pickColumns([
    'status',
    'orderAction',
    'gtc',
    'product',
    'currencyPair',
    'way',
    'notional',
    'notionalCurrency',
    'amountDone',
    'limitPrice',
    'customerPrice',
    'account',
    'updateTime',
    'executionPrice',
    'startTime',
    'endTime',
    'maturityDate',
    'liquidityPool',
    'clippingMode',
    'clipSize',
    'randomize',
    'swapPoints',
    'forwardPrice',
    'remainingAmount',
    'averagePrice',
    'noWorseThan',
    'spreadCapture',
    'alphaSeeker',
    'speed',
    'fixingBenchmark',
    'fixingPlace',
    'fixingTime',
    'fixingDateUtc',
    'fixingPriceType',
    'fixingMarginType',
    'marginInBps',
    'price',
  ]),
  sortModel: defaultBlotterSortModel,
  rowModel: {},
};

export const defaultAccumulatorTab: BlotterTabMetadata = {
  autoColumState: defaultAutoColumnState,
  columnsState: pickColumns([
    'defaultAction',
    'status',
    'product',
    'currencyPair',
    'way',
    'expiry',
    'premium',
    'premiumCurrency',
    'premiumSide',
    'date',
    'time',
    'venue',
    'account',
    'salesFullName',
    'portfolio',
    'fixingFrequency',
    'settlementFrequency',
    // Accrued notional / Total un-leverage notional
    // Accrued target / Total target
    // Expired fixings / Number of fixing
    // Next leverage
    // Next fixing date
    // Next strike
    'koConventionType',
    'koTime',
    // EKI
  ]),
  sortModel: defaultBlotterSortModel,
  rowModel: {},
};

export const blotterDetailsPanelHidden: BlotterDetailsPanel = {
  selectedId: null,
  childrenIds: null,
  mode: null,
  hasUnexpectedError: false,
};

export const defaultBlotterMetadata: BlotterMetadata = {
  isOpen: false,
  mode: 'intraday',
  fetching: 'intraday',
  canRefetch: true,
  errors: [],
  activeTab: 'cash',
  panelHeight: 300,
  enableShowMyMainDeals: true,
  orderRejectedId: null,
  showSaveErrorDialogBox: false,
  cashTab: defaultCashTab,
  optionTab: defaultOptionTab,
  orderTab: defaultOrderTab,
  accumulatorTab: defaultAccumulatorTab,
  detailsPanel: blotterDetailsPanelHidden,
  cantReopenReason: null,
};
