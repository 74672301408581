import { isDefined } from '@sgme/fp';
import { Button, Modal } from '@sgme/ui';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../state';
import { actionCreators } from '../../../state/actions';
import { selectors } from '../../../state/selectors';
import { getUserPreferenceData } from '../../../state/userPreferences/selectors';
import { SimpleEmailsInput } from '../../share/SimpleEmailsInput';
import { MenuButton } from './MenuButton';

export const SharedTabButton = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const tabId = useAppSelector(selectors.getClientWorkspaceActiveTab);

  const allSavedEmails = useAppSelector((state) =>
    tabId !== null ? selectors.getTabSharedWithEmails(state, tabId) : [],
  );

  const isShared = allSavedEmails.length > 0;
  const ownerEmail = useAppSelector((state) =>
    isDefined(tabId) ? selectors.getTabOwnerEmail(state, tabId) : undefined,
  );
  const userEmail = useAppSelector(getUserPreferenceData).emails[0];

  const canEditSharingEmails = !isShared || ownerEmail === userEmail;

  const [allEmails, setAllEmails] = useState(allSavedEmails);

  useEffect(() => {
    setAllEmails(allSavedEmails);
  }, [tabId]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSave = () => {
    if (tabId === null) {
      return;
    }

    dispatch(actionCreators.tabSharingEmailsUpdated(tabId, allEmails));

    if (allEmails.length === 0) {
      dispatch(actionCreators.stopTabSharingThunk(tabId));
    } else {
      dispatch(actionCreators.saveSharedTabThunk(tabId));
    }

    setIsOpen(false);
  };

  const onUpdateEmails = (newEmails: string[]) => {
    setAllEmails(newEmails);
  };

  if (tabId === null) {
    return null;
  }

  return (
    <>
      <MenuButton
        color={isShared ? 'info' : 'primary'}
        disabled={!canEditSharingEmails}
        e2eHandle="share-tab"
        onClick={openModal}
        tooltip={<FormattedMessage id="navMenuButtons.editSharing" />}
      >
        <i className="icon icon-md">share</i>
      </MenuButton>

      {/*MODAL*/}

      <Modal show={isOpen} onClose={closeModal}>
        <Modal.Header>
          <FormattedMessage id="navMenuButtons.editSharing.modal.header" />
        </Modal.Header>

        <Modal.Body>
          <SimpleEmailsInput emails={allEmails} onChange={onUpdateEmails} dataE2e="share-tab-modal" />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={closeModal} data-e2e="share-tab-modal-cancel">
            <FormattedMessage id="navMenuButtons.editSharing.modal.cancel" />
          </Button>

          <Button
            purpose="primary"
            onClick={onSave}
            disabled={!isShared && allEmails.length === 0}
            data-e2e="share-tab-modal-save"
          >
            {isShared ? (
              allEmails.length > 0 ? (
                <FormattedMessage id="navMenuButtons.editSharing.modal.save" />
              ) : (
                <FormattedMessage id="navMenuButtons.editSharing.modal.stopSharing" />
              )
            ) : (
              <FormattedMessage id="navMenuButtons.editSharing.modal.share" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
