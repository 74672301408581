import { getAmericanForwardMetadata } from './factories';

export const getAmericanForwardCurrentRfsStreamId = getAmericanForwardMetadata('currentStreamId');
export const getAmericanForwardDisplayPriceType = getAmericanForwardMetadata('displayPriceType');

export const getAmericanForwardSolvingType = getAmericanForwardMetadata('solvingType');

export const getAmericanForwardLastStreamId = getAmericanForwardMetadata('lastStreamId');

export const getAmericanForwardCurrentSessionId = getAmericanForwardMetadata('currentSessionId');
export const getAmericanForwardPropertiesRequested = getAmericanForwardMetadata('propertiesRequested');
export const getAmericanForwardPremiumWay = getAmericanForwardMetadata('premiumWay');

export const getAmericanForwardCurrentEspStreamId = getAmericanForwardMetadata('currentEspStreamId');
export const getAmericanForwardCurrentExecutionId = getAmericanForwardMetadata('currentExecutionId');
export const getAmericanForwardIsPriceable = getAmericanForwardMetadata('isPriceable');
export const getAmericanForwardLastExecutedQuoteId = getAmericanForwardMetadata('lastExecutedQuoteId');
export const getAmericanForwardLastStreamError = getAmericanForwardMetadata('lastStreamError');
export const getAmericanForwardPriceRecords = getAmericanForwardMetadata('priceRecords');
export const getAmericanForwardPropertiesRequestError = getAmericanForwardMetadata('propertiesRequestError');
export const getAmericanForwardTradeCaptureIdVersion = getAmericanForwardMetadata('tradeCaptureIdVersion');
export const getAmericanForwardRfsStartedAt = getAmericanForwardMetadata('rfsStartedAt');
