import { actionCreatorTypeExtractor } from 'bootstrap/reduxMiddlewares/actionNotificationMiddleware';
import { GLOBAL_ERROR_AUTH_EXPIRED, type IStreamError } from 'state/globalError/globalErrorModel';
import type { AppState } from 'state/model';
import { notifyDisconnection } from 'state/session/sessionActions';
import { getTileLastStreamError } from 'state/tile/selectors';
import { getExecutionById } from '../executions/executionsSelectors';
import { authExpiredAction, noClientsAction, noProductsAction } from './globalErrorActions';

const notCrashErrors = [authExpiredAction, notifyDisconnection, noClientsAction, noProductsAction].map(
  actionCreatorTypeExtractor,
);

export function getGlobalError(state: AppState) {
  return state.globalError.error;
}

export function isAuthExpired(state: AppState) {
  return state.globalError.error === GLOBAL_ERROR_AUTH_EXPIRED;
}

export function isUserOffline(state: AppState) {
  return state.globalError.isOffline;
}

export function isNoClients(state: AppState) {
  return state.globalError.error === 'NO_CLIENTS';
}

export function isNoProducts(state: AppState) {
  return state.globalError.error === 'NO_PRODUCTS';
}

export function isCrashError(state: AppState) {
  return !(notCrashErrors as ReadonlyArray<string | null>).includes(state.globalError.error);
}

export function getStreamError(state: AppState): IStreamError | null {
  const tileId = state.globalError.tileId;
  if (tileId !== null) {
    return getTileLastStreamError(state, tileId);
  }

  const executionId = state.globalError.executionId;
  if (executionId !== null) {
    const executionState = getExecutionById(state, executionId);
    return executionState.status === 'Error' ? executionState.streamError : null;
  }

  return null;
}
