import { unminifyError } from '@sgme/stacktrace';
import { sendHelpRequest } from 'api/help';
import { actionCreatorTypeExtractor, actionNotifier } from 'bootstrap/reduxMiddlewares/actionNotificationMiddleware';
import { tryCatchMiddleware } from 'bootstrap/reduxMiddlewares/tryCatchMiddleware';
import { crash } from 'circuitBreaker';
import type { Action } from 'state/actions';
import { blotterOrderListReceived, blotterTabMetadataChanged } from 'state/blotter/action';
import { espPriceReceived } from 'state/esp/espStreamsActions';
import { type AppCrashedAction, authExpiredAction, createAppCrashedAction } from 'state/globalError';
import { gridItemDrag, gridItemDragEnd, gridItemSizeChange } from 'state/gridLayout/gridLayoutActions';
import { notifyDisconnection } from 'state/session/sessionActions';
import { type ITakeOptions, take as takeScreenshot } from 'utils/screenshot';
import { crashHandler } from './crashEventHandler';
import { logActionCallback } from './logActionCallback';

export const logActionMiddleware = actionNotifier({
  didNotMatch: logActionCallback,
  actionsList: [espPriceReceived, gridItemSizeChange, gridItemDrag, gridItemDragEnd].map(actionCreatorTypeExtractor),
});

export const crashOnActionMiddleware = actionNotifier({
  didMatch: crash,
  actionsList: [createAppCrashedAction, authExpiredAction, notifyDisconnection].map(actionCreatorTypeExtractor),
});

export const autosendHelpRequestMiddleware = (isDev: boolean) => {
  const actionsList = isDev ? [] : [actionCreatorTypeExtractor(createAppCrashedAction)];
  function shouldIgnoreElement(element: Element): boolean {
    const { classList } = element;
    return Boolean(
      (classList &&
        (classList.contains('life-ring-modal-component') ||
          classList.contains('modal') ||
          classList.contains('modal-backdrop'))) ||
        element.getAttribute('role') === 'dialog',
    );
  }
  const options: ITakeOptions = {
    element: document.body,
    shouldIgnoreElement: (el) => shouldIgnoreElement(el),
  };

  return actionNotifier({
    didMatch: async (err: AppCrashedAction) => {
      const { error, source } = err;

      const newScreenshot = await takeScreenshot(options);
      const screenshot = newScreenshot.toDataURL('image/jpeg', 0.1); // LOW Quality img

      const handledError = await unminifyError(error);
      const errorMessage = handledError?.message;
      const beautifulStackTrace = handledError?.stack;

      let details = '';
      if (errorMessage !== '' && beautifulStackTrace !== '') {
        details = `Automatic help request sent after APP_CRASHED from: ${source}\n ${errorMessage}:\n${beautifulStackTrace}`;
      } else {
        details = `APP_CRASHED unabled to handled correctly the error ${JSON.stringify(err)}`;
      }

      sendHelpRequest({
        config: 'appcrashed',
        details,
        includeLastActions: true,
        includeState: true,
        sendConfirmation: false,
        screenshot,
      });
    },
    actionsList,
  });
};

export const catchMiddleware = tryCatchMiddleware(crashHandler);

const fakeQuoteReply = () => ({ type: 'QUOTE.REPLY' });
export const rememberLastActionsMiddleware = (actionsBuffer: Action[] = [], maxActions = 50) =>
  actionNotifier({
    didNotMatch: (action) => {
      actionsBuffer.unshift(action);
      actionsBuffer.splice(maxActions);
    },
    actionsList: [
      fakeQuoteReply,
      espPriceReceived,
      blotterOrderListReceived,
      blotterTabMetadataChanged,
      gridItemSizeChange,
      gridItemDrag,
      gridItemDragEnd,
    ].map(actionCreatorTypeExtractor),
  });
