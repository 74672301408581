import type { Middleware } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import { getSharedTabsToSendWith } from '../../epics/metaSelectors/workspace/saveWorkspaceSelectors';
import type { DispatchExt, ExtraArgument } from '../../state';
import type { Action } from '../../state/globalActions';
import type { AppState } from '../../state/model';
import { selectors } from '../../state/selectors';
import { loadAllSharedTabsThunk } from './sharedTabThunks';

export const sharedTabMiddleware: Middleware<
  DispatchExt,
  AppState,
  ThunkDispatch<Readonly<AppState>, ExtraArgument, Action>
> =
  ({ getState, dispatch }) =>
  (next) =>
  (action: Action) => {
    const currentState = getState();
    next(action);
    const newState = getState();

    // wait required data to init shared tabs / tiles
    if (action.type === 'REFERENCE_DATA_RECEIVED') {
      dispatch(loadAllSharedTabsThunk());

      return;
    }

    // TODO: optimize

    const allSharedCurrentTabs = getSharedTabsToSendWith(selectors)(currentState);
    const allSharedNewTabs = getSharedTabsToSendWith(selectors)(newState);

    if (allSharedCurrentTabs !== allSharedNewTabs) {
      //saveUpdatedSharedTab(allSharedCurrentTabs, allSharedNewTabs, dispatch);
    }
  };

// const saveUpdatedSharedTab = (
//   allSharedCurrentTabs: SharedTab[],
//   allSharedNewTabs: SharedTab[],
//   dispatch: ThunkDispatch<Readonly<AppState>, ExtraArgument, Action>,
// ) => {
//   const currentSharedTabById = createRecord(allSharedCurrentTabs, (tab) => tab.tabId);
//   const newSharedTabById = createRecord(allSharedNewTabs, (tab) => tab.tabId);
//
//   // create or update
//   for (const entry of Object.entries(newSharedTabById)) {
//     const [tabId, newTab] = entry;
//
//     const currentTab = currentSharedTabById[tabId];
//
//     console.log(`=== newTab.isSynchronizable`, newTab.isSynchronizable);
//
//     if (!newTab.isSynchronizable || newTab === currentTab) {
//       continue;
//     }
//
//     const { isSynchronizable: isCurrentSynchronizable, ...currentSynchronizableTabe } = currentTab;
//     const { isSynchronizable: isNewSynchronizable, ...newSynchronizableTabe } = newTab;
//
//     const hasChanges = !isDeepEqual(newSynchronizableTabe, currentSynchronizableTabe);
//
//     if (hasChanges) {
//       console.log(`=== changes detected in a shared tab ${tabId}`);
//       console.log(`=== currentSynchronizableTabe`, currentSynchronizableTabe);
//       console.log(`=== newSynchronizableTabe`, newSynchronizableTabe);
//
//       dispatch(saveSharedTabThunk(tabId));
//     }
//   }
//
//   // stop sharing
//   for (const entry of Object.entries(currentSharedTabById)) {
//     const [tabId, _currentTab] = entry;
//
//     if (newSharedTabById[tabId] === undefined) {
//       dispatch(stopTabSharingThunk(tabId));
//     }
//   }
// };
