import type { AppState } from 'state/model';
import { type TileInstrument, isTileInstrument } from 'state/referenceData/referenceDataModel';
import type { Selectors } from 'state/selectors';
import type { MapStateToPropsHOF } from 'typings/redux-utils';

export interface TileInstrumentConnectOwnProps {
  quoteId: string;
}

export interface TileInstrumentConnectStateProps {
  instrument: TileInstrument;
}

// you can whitelist selectors after implementation
export type TileInstrumentConnectSelectors = Selectors;

export const mapStateToPropsTileInstrument: MapStateToPropsHOF<
  TileInstrumentConnectStateProps,
  TileInstrumentConnectOwnProps,
  AppState,
  TileInstrumentConnectSelectors
> =
  (sl) =>
  (state, { quoteId }) => {
    const { instrument } = sl.getTileState(state, quoteId);

    if (isTileInstrument(instrument)) {
      return { instrument };
    }

    throw new Error(`invalid tile instrument : ${instrument}`);
  };
