import type { SharedTile } from '../../../../epics/metaSelectors/workspace/saveWorkspaceSelectors';
import { type Patcher, composeLeftPatcher } from '../../../../utils/stateMap';
import type { InstrumentChoice } from '../../../referenceData';
import type { GridState } from '../../gridLayoutModels';
import { GRID_LAYOUT_AUTHORIZED_INSTRUMENTS } from '../gridCollectionReducer';
import { restoreGridItem } from './restoreGridItem';
import { updateAddTilePositions } from './updateAddTilePositions';

export const updateFullGridLayout =
  (tiles: SharedTile[]): Patcher<GridState> =>
  (gridState) => {
    if (gridState.draggingStatus !== 'IDLE') {
      return {};
    }

    let updatedGridState: GridState = {
      draggingStatus: 'IDLE',
      gridLayout: {
        columns: [[]],
        gridItemSizes: {},
        gridItemPositions: {},
      },
      addTilePositions: [
        {
          top: 0,
          left: 0,
        },
      ],
    };

    for (const tile of tiles) {
      const { instrument, productName } = tile;

      const instrumentChoice: InstrumentChoice =
        instrument !== 'Accumulator'
          ? instrument
          : productName === 'FxForwardAccumulator'
            ? 'ForwardAccumulator'
            : 'TargetAccumulator';

      if (GRID_LAYOUT_AUTHORIZED_INSTRUMENTS.includes(instrumentChoice)) {
        updatedGridState = composeLeftPatcher(
          restoreGridItem(tile.tileId, tile),
          updateAddTilePositions,
        )(updatedGridState);
      }
    }

    return updatedGridState;
  };
