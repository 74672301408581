import { type ErrorInfo, PureComponent, type ReactNode } from 'react';

interface CatchState {
  hasError: boolean;
}

interface CatchProps {
  children: ReactNode;
  onCatch(error: Error, errorInfo: ErrorInfo): void;
}

export class Catch extends PureComponent<CatchProps, CatchState> {
  public state = { hasError: false };

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error catched', error);
    this.props.onCatch(error, errorInfo);
  }

  public render() {
    return this.state.hasError ? null : this.props.children;
  }
}
