import { VStack } from '@sgme/ui';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import type { GridItemSize } from '../../state/gridLayout/gridLayoutModels';
import type { ProductName } from '../../state/share/productModel';
import { IntlComponentBoundary } from '../../utils/i18n/IntlComponentBoundary';
import en from './locales/en.json';
import fr from './locales/fr.json';

export type UnauthorizedInstrumentProps = {
  size: GridItemSize;
  productName: ProductName;
};

export const UnauthorizedInstrument = (props: UnauthorizedInstrumentProps) => {
  const { size, productName } = props;

  return (
    <IntlComponentBoundary messagesMap={translations}>
      <UnauthorizedInstrumentStyle style={{ width: size.width, height: size.height }}>
        <VStack verticalAlignment="center" horizontalAlignment="center" className="p-16px bg-lvl3 w-100 h-100">
          <i className="icon">report</i>
          <FormattedMessage id={`item.not-authorized.${productName}`} values={{ productName }} />
        </VStack>
      </UnauthorizedInstrumentStyle>
    </IntlComponentBoundary>
  );
};

const UnauthorizedInstrumentStyle = styled.div`
  padding: 5px; // can't be done with bootstrap class
`;

const translations = {
  fr,
  en,
};
