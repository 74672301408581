import * as e2e from 'components/Form/Inputs/e2e';
import { CloseTile } from 'components/FxTileHeader/CloseTile';
import { TileLayout } from 'components/FxTileLayout';
import { withQuoteId, withWorkspaceId } from 'components/contexts';
import { IsInternal } from 'components/share/UserType';
import { tagWithProps } from 'components/share/tagWithProps';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { connectQuoteError, connectTileSize } from './connect';

const errorClass = 'd-flex flex-column my-auto align-items-center text-center';

const titleClass = 'fw-bold text-danger';

const span = tagWithProps('span');
const Danger = span({ className: 'text-danger' });
const DangerTitle = span({ className: titleClass });

const CloseTileContainer = styled.div`
  top: 0;
  right: 0;
`;

export interface TileErrorProps {
  height: number;
  width: number;
  close?: boolean;
  reset(): void;
  showErrorDetails(): void;
}

const br = { br: <br /> };
export function TileErrorRaw({ reset, close = false, showErrorDetails, height, width }: TileErrorProps) {
  return (
    <TileLayout layout="free-width" header={null} height={height} width={width}>
      <CloseTileContainer className="position-absolute p-2">
        <CloseTile />
      </CloseTileContainer>

      <div className={errorClass} data-e2e={e2e.message('tile-error')}>
        <i className="icon icon-xl text-danger">error_outline</i>
        <span className="mb-3" />
        <FormattedMessage id="tradecapture.error.title">{DangerTitle}</FormattedMessage>
        <span className="mb-3" />
        <FormattedMessage id="tradecapture.error.message" values={br}>
          {Danger}
        </FormattedMessage>
        <span className="mb-4" />
        <button className="btn btn-flat-danger" type="button" data-e2e={e2e.button('reset-tile')} onClick={reset}>
          <FormattedMessage id={close ? 'tradecapture.error.close' : 'tradecapture.error.reset'} />
        </button>
        <span className="mb-1" />
        <IsInternal>
          <button
            className="btn btn-sm btn-danger"
            type="button"
            onClick={showErrorDetails}
            data-e2e={e2e.button('show-error-detail')}
          >
            View SG log
          </button>
        </IsInternal>
      </div>
    </TileLayout>
  );
}

export const TileError = withWorkspaceId(withQuoteId(connectTileSize(connectQuoteError(TileErrorRaw))));
