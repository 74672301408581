import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../state';
import { actionCreators } from '../../../state/actions';
import { selectors } from '../../../state/selectors';

export type CloseTabButtonProps = {
  tabId: string;
};

export const CloseTabButton = (props: CloseTabButtonProps) => {
  const { tabId } = props;

  const dispatch = useDispatch();

  const isShared = useAppSelector((state) => selectors.isTabShared(state, tabId));

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (isShared) {
      dispatch(actionCreators.stopTabSharingThunk(tabId));
    }

    dispatch(actionCreators.clientWorkspaceTabClosedThunk(tabId));
  };

  return (
    <button type="button" className="align-self-baseline btn btn-flat-secondary btn-icon p-0 ms-1" onClick={onClick}>
      <i className="icon icon-sm px-2">close</i>
    </button>
  );
};
