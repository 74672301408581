import type { BlotterEntry, BlotterTradeValues } from 'state/blotter/blotterEntryModel';

export type EnhancedBlotterEntry = BlotterEntry & { values: { accountDisplayName: string | null } };

export const getCounterPartyBdrId = (data: any): number | null => {
  const ref = data as EnhancedBlotterEntry;
  const values =
    ref.instrument === 'Cash' || ref.instrument === 'Option' || ref.instrument === 'AmericanForward'
      ? (ref.values as BlotterTradeValues)
      : null;

  return values?.counterpartyBdrId || null;
};
