import type { ClientWorkspaceTileDeleted } from 'state/clientWorkspace/clientWorkspaceActions';
import { filterArrayByMatchingKey, removeKey } from 'utils/stateMap';
import type { GridLayout, GridState, Locus } from '../../gridLayoutModels';
import { getDownwardNeighbourGridItem } from '../utils';
import { reduceGridItemVerticalOffsetPropagation } from '../utils/moveGridItems';

export const removeGridItem =
  (action: ClientWorkspaceTileDeleted) =>
  ({ gridLayout }: GridState): Pick<GridState, 'gridLayout'> => {
    const columnUpdater = filterArrayByMatchingKey<Locus, 'gridItemId'>('gridItemId', action.tileId);

    const newGridItemSizes = removeKey(gridLayout.gridItemSizes, action.tileId);

    // Break if nothing remove
    if (newGridItemSizes === gridLayout.gridItemSizes) {
      return { gridLayout };
    }

    const newGridLayout: GridLayout = {
      gridItemSizes: newGridItemSizes,
      gridItemPositions: removeKey(gridLayout.gridItemPositions, action.tileId),
      columns: gridLayout.columns.map(columnUpdater),
    };

    return {
      gridLayout: {
        ...gridLayout,
        ...reduceGridItemVerticalOffsetPropagation(
          newGridLayout,
          getDownwardNeighbourGridItem(gridLayout, action.tileId),
        ),
      },
    };
  };
