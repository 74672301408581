import type { Thunk } from 'state';
import {
  type BlotterEntry,
  isTradeBlotterEntry,
  type OrderBlotterEntry,
  type OrderResultMode,
  type TradeBlotterEntry,
  type TradeType,
} from '../blotterEntryModel';
import type { Client } from 'state/referenceData';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { ProductName } from 'state/share/productModel';
import type { CashTileOpenFromBlotter } from 'state/fxCashs/fxCashsModel';
import type { CurrencyChoice, HedgeType, Side } from 'state/share/productModel/litterals';
import { assertUnreachable, isDefined, isString } from '@sgme/fp';
import type { SwapTileOpenFromBlotter } from 'state/fxSwaps/fxSwapsModel';
import type { OptionType, SettlementType } from 'state/fxOptions/fxOptionsModel';
import { getCounterPartyBdrId } from 'components/Blotter/LiveBlotter/liveBlotterType';
import { mapOptional } from 'utils/optional';
import { isNotPureArray } from 'utils/predicates';
import type { CantReopenReason } from '../blotterModel';
import type { LegOpenFromBlotter, OptionTileOpenFromBlotter } from 'state/fxOptions/actions/optionProduct';
import type { AmericanForwardTileOpenFromBlotter } from '../../fxAmericanForward/model/fxAmericanForwardProductModel';

type TileToReopen = CashTileOpenFromBlotter | SwapTileOpenFromBlotter | OptionTileOpenFromBlotter | AmericanForwardTileOpenFromBlotter;

export function tileOpenFromTradeBlotterEntryThunk(blotterEntryId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    dispatch(ac.blotterOpenTileFromTrade()); // Only used for matomo tracking

    const state = getState();
    const blotterEntry = sl.getBlotterDataById(state, blotterEntryId);

    if (!isTradeBlotterEntry(blotterEntry)) {
      dispatch(ac.blotterCantReopenEntry('not_a_trade'));
      return;
    }

    const client = getClient(sl, state, blotterEntry);

    if (client === null) {
      dispatch(ac.blotterCantReopenEntry('client_missing'));
      return;
    }

    const tile = getTileToOpen(sl, state, blotterEntry);

    if (typeof tile === 'string') {
      dispatch(ac.blotterCantReopenEntry(tile));
      return;
    }

    dispatch(ac.clientWorkspaceTabAddedThunk('tiles', client.companyId.toString(), true));
    dispatch(ac.tileOpenedFromBlotterThunk(tile));
  };
}

function getTileToOpen(
  sl: Selectors,
  state: AppState,
  blotterEntry: TradeBlotterEntry,
): TileToReopen | CantReopenReason {
  const productName = productMapper[blotterEntry.values.product];

  if (productName === undefined) {
    return 'unexpected_product';
  }

  if (!sl.isProductAuthorized(state, productName)) {
    return 'unauthorized_product';
  }

  const { currencyPair } = blotterEntry.values;
  const amountCurrency = getCurrencyChoice(blotterEntry.values.dealtCurrency, currencyPair);

  switch (productName) {
    case 'FxSpot':
    case 'FxFwd':
    case 'FxNdf': {
      const tile: CashTileOpenFromBlotter = {
        instrument: 'Cash',
        productName,
        currencyPair,
        isNonDeliverable: blotterEntry.values.product === 'Ndf',
        amount: blotterEntry.values.dealtAmount ?? undefined,
        amountCurrency,
        maturityDateTenor:
          extractDay(
            blotterEntry.values.product === 'Spot' ? blotterEntry.values.spotDate : blotterEntry.values.farDate,
          ) ?? undefined,
      };
      return tile;
    }

    case 'FxSwap': {
      const tile: SwapTileOpenFromBlotter = {
        instrument: 'Swap',
        productName,
        isNonDeliverable: sl.isCurrencyPairNeverDeliverable(state, currencyPair),
        currencyPair,
        amountCurrency,
        nearAmount: blotterEntry.values.dealtAmount ?? undefined,
        farAmount: blotterEntry.values.farDealtAmount ?? undefined,
        nearPaymentDateTenor: extractDay(blotterEntry.values.nearDate) ?? undefined,
        farPaymentDateTenor: extractDay(blotterEntry.values.farDate) ?? undefined,
      };
      return tile;
    }

    case 'FxOption': {
      const optionType = getOptionType(blotterEntry);

      if (optionType === null) {
        return 'invalid_option_type';
      }

      const childrenBlotterEntries =
        blotterEntry.childrenIds === undefined
          ? []
          : (sl.getBlotterChildrenTrades(state, blotterEntry.childrenIds) as TradeBlotterEntry[]);

      const legs = childrenBlotterEntries.filter(({ values: { product } }) => product === 'Vanilla').map(getLegToOpen);

      if (isNotPureArray(isString, legs)) {
        return 'invalid_leg';
      }

      const hedgeEntry = getStrategyHedgeEntry(childrenBlotterEntries);
      const hedgeType = getOptionHedgeType(hedgeEntry);

      if (hedgeType === null) {
        return 'unknown_hedge_type';
      }

      const tile: OptionTileOpenFromBlotter = {
        instrument: 'Option',
        productName: 'FxOption',
        optionType,
        strategyType: blotterEntry.values.product as TradeType,
        hedgeType,
        currencyPair,
        premiumCurrency: getCurrencyChoice(blotterEntry.values.premiumCurrency, blotterEntry.values.currencyPair),
        priceType: sl.getUserPreferenceData(state).defaultPriceType,
        side: getTileSide(blotterEntry),
        expiryDate: extractDay(blotterEntry.values.expiry),
        strike: blotterEntry.values.strike?.toString(),
        notionalAmount: blotterEntry.values.dealtAmount?.toString(),
        notionalCurrency: amountCurrency,
        deliveryDate: extractDay(blotterEntry.values.deliveryDate),
        settlementType: getSettlementType(blotterEntry.values.deliveryType),
        settlementPlace: blotterEntry.values.cutOffPlace,
        premiumDate: extractDay(blotterEntry.values.premiumPaymentDate),
        legs,
      };

      return tile;
    }

    case 'FxAmericanForward': {
      const {
        expiry,
        premiumCurrency,
        forwardRate,
        dealtAmount,
        deliveryDate,
        premiumPaymentDate,
        callabilityStartDate,
        cutOffPlace,
        premium,
      } = blotterEntry.values;

      const tile: AmericanForwardTileOpenFromBlotter = {
        callabilityStartDate: extractDay(callabilityStartDate),
        currencyPair,
        deliveryDate: extractDay(deliveryDate),
        expiryDate: extractDay(expiry),
        forwardRate: forwardRate?.toString(),
        hedgeType: 'Live',
        instrument: 'AmericanForward',
        marketPlace: cutOffPlace,
        notionalAmount: dealtAmount?.toString(),
        notionalCurrency: amountCurrency,
        optionType: 'Call',
        premiumCurrency: getCurrencyChoice(premiumCurrency, currencyPair),
        premiumDate: extractDay(premiumPaymentDate),
        priceType: 'AMOUNT',
        productName: 'FxAmericanForward',
        side: getTileSide(blotterEntry),
        premiumPaymentAmount: premium?.toString(),
        // TODO ABO, how to get it ?
        premiumTypeString: 'AMOUNT',
        hedgeAmount: 'TBD',
        hedgeCurrency: 1,
        hedgeRate: '1.02',
      };

      return tile;
    }

    default:
      return 'unhandled_product';
  }
}

const getLegToOpen = (blotterEntry: TradeBlotterEntry): LegOpenFromBlotter | CantReopenReason => {
  const optionType = getOptionType(blotterEntry);

  if (optionType === null) {
    return 'invalid_option_leg_type';
  }

  return {
    optionType,
    side: getTileSide(blotterEntry),
    expiryDate: extractDay(blotterEntry.values.expiry),
    strike: blotterEntry.values.strike?.toString(),
    notionalAmount: blotterEntry.values.dealtAmount?.toString(),
    notionalCurrency: getCurrencyChoice(blotterEntry.values.dealtCurrency, blotterEntry.values.currencyPair),
    deliveryDate: extractDay(blotterEntry.values.deliveryDate),
    settlementType: getSettlementType(blotterEntry.values.deliveryType),
    settlementPlace: blotterEntry.values.cutOffPlace,
    premiumDate: extractDay(blotterEntry.values.premiumPaymentDate),
  };
};

const productMapper: Record<TradeType, ProductName> = {
  Ndf: 'FxNdf',
  Nds: 'FxSwap',
  Outright: 'FxFwd',
  Spot: 'FxSpot',
  Strategy: 'FxOption',
  Swap: 'FxSwap',
  TargetAccumulator: 'FxTargetAccumulator',
  ForwardAccumulator: 'FxForwardAccumulator',
  Vanilla: 'FxOption',
  Straddle: 'FxOption',
  Strangle: 'FxOption',
  RiskReversal: 'FxOption',
  AmericanForward: 'FxAmericanForward',
};

function getTileSide(blotterEntry: TradeBlotterEntry): Side {
  const value = blotterEntry.values.way?.trim()?.toLowerCase();
  switch (value) {
    case 'buy':
      return 'Buy';
    case 'sell':
      return 'Sell';
    default:
      return 'Buy';
  }
}

export function tileOpenedFromBlotterThunk(
  tile: CashTileOpenFromBlotter | SwapTileOpenFromBlotter | OptionTileOpenFromBlotter | AmericanForwardTileOpenFromBlotter,
): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl, getNewGuid }) => {
    const state = getState();
    const tabId = sl.getClientWorkspaceActiveTab(state);
    const tileId = getNewGuid();

    const isOptionGrouped =
      tile.instrument === 'Option' ? sl.getUserPreferenceData(getState()).optionStrategyGroupLegs : null;

    const isOptionGreekAndMktExpanded =
      tile.instrument === 'Option' ? sl.getUserPreferenceData(getState()).optionExpandGreekAndMkt : null;

    if (tabId) {
      dispatch(
        ac.clientWorkspaceTileReopened(
          tabId,
          tileId,
          tile.instrument,
          tile.productName,
          isOptionGrouped,
          isOptionGreekAndMktExpanded,
        ),
      );
    }

    switch (tile.instrument) {
      case 'Cash':
        dispatch(ac.cashTileOpenFromBlotterEpic(tileId, tile));
        break;

      case 'Swap':
        dispatch(ac.swapTileOpenFromBlotterEpic(tileId, tile));
        break;

      case 'Option':
        if (tile.legs.length > 1) {
          dispatch(ac.optionToggleStrategyThunk(tileId, true));

          const firstNotionalCurrency = tile.legs[0].notionalCurrency;

          if (tile.legs.some(({ notionalCurrency }) => notionalCurrency !== firstNotionalCurrency)) {
            dispatch(ac.optionLegsCurrencyLinkedToggled(tileId));
          }
        }

        dispatch(ac.optionTileOpenFromBlotterEpic(tileId, tile));
        break;

      case 'AmericanForward':
        dispatch(ac.americanForwardTileOpenFromBlotterEpic(tileId, tile));
        break

      default:
        assertUnreachable(tile, 'Reopen not implemented for new instrument');
    }

    dispatch(ac.checkCurrencyPairInversion(tileId, tile.currencyPair ?? null));
  };
}

function getCurrencyChoice(
  currency: string | null | undefined,
  currencyPair: string | null | undefined,
): CurrencyChoice | undefined {
  if (!currencyPair || !currency) {
    return undefined;
  }
  return currencyPair.startsWith(`${currency}/`) ? 1 : 2;
}

function getSettlementType(deliveryType: string | null): SettlementType | undefined {
  const value = deliveryType?.trim()?.toLowerCase();
  switch (value) {
    case 'cash':
      return 'Cash';
    case 'physical_spot':
      return 'Physical';
    default:
      return undefined;
  }
}

const extractDay = mapOptional((date: string) => date.replace(/T.*$/, ''));

function getOptionHedgeType(hedgeEntry: TradeBlotterEntry | undefined): HedgeType | null {
  switch (hedgeEntry?.values.product) {
    case undefined:
      return 'Live';
    case 'Spot':
      return 'Spot';
    case 'Outright':
      return 'Forward';
    default:
      return null;
  }
}

/**
 * Get the type of option (Call or Put)
 *
 * returns undefined for strategies amd null when the type is unknown
 * @param blotterEntry
 */
function getOptionType(blotterEntry: TradeBlotterEntry): OptionType | null | undefined {
  if (blotterEntry.values.product === 'Strategy' || blotterEntry.values.product !== 'Vanilla') {
    return undefined;
  }
  switch (
  blotterEntry.values.optionType) {
    case 'CE':
    case 'CECSD1':
    case 'CECSD2':
    case 'CEFWD':
    case 'Call':
      return 'Call';
    case 'PE':
    case 'PECSD1':
    case 'PECSD2':
    case 'PEFWD':
    case 'Put':
      return 'Put';
    default:
      return null;
  }
}

const getStrategyHedgeEntry = (childrenBlotterEntries: TradeBlotterEntry[]) =>
  childrenBlotterEntries.find(entry => entry.values.product !== 'Vanilla');

function getClient(sl: Selectors, state: AppState, blotterEntry: BlotterEntry): Readonly<Client> | null {
  const clientId = getCounterPartyBdrId(blotterEntry);
  return clientId !== null ? sl.getUserClientById(state, clientId.toString()) ?? null : null;
}

export function openOrderTileFromBlotterEntryThunk(orderId: string, mode: OrderResultMode): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    const blotterEntry = sl.getBlotterDataById(state, orderId);
    const client = getClient(sl, state, blotterEntry);

    if (blotterEntry.instrument !== 'Order') {
      return;
    }

    const orderAccount = blotterEntry.values.account;
    if (!orderAccount) {
      dispatch(ac.blotterCantReopenEntry('client_missing'));
      return;
    }

    const tabId = sl.getClientWorkspaceActiveTab(state);

    if (tabId) {

      dispatch(clientWorkspaceTabAddedAndOpenFromBlotterThunk(mode, orderId, tabId, client?.companyId.toString()))
    }
  };
}
export function clientWorkspaceTabAddedAndOpenFromBlotterThunk(mode: OrderResultMode, orderId: string, tabId: string, tabClientId?: string): Thunk<void> {
  return async (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const blotterEntry = sl.getBlotterDataById(state, orderId) as OrderBlotterEntry;

    const orderAccount = blotterEntry.values.account;

    const tabAccount = sl.getClientWorkspaceTab(state, tabId).clientId;

    const tileAccount = orderAccount === tabAccount ? null : orderAccount;


    const tabType = sl.getTabType(state, tabId)
    if (tabType === "bulkOrder" || tabType === "bulkTrade") {
      // the await is needed in order to get the new state
      await dispatch(ac.clientWorkspaceTabAddedThunk('tiles', tabClientId, true))
    }
    const newState = getState();

    const newTabId = sl.getClientWorkspaceActiveTab(newState);

    const usedTabId = tabType === 'tiles' ? tabId : isDefined(newTabId) ? newTabId : tabId


    if (mode === 'Edit') {
      dispatch(ac.blotterEditedOrderCreateEntry(orderId, blotterEntry));
    }
    dispatch(ac.blotterOrderOpenTile(orderId, mode, tileAccount, usedTabId));


  };
}

export function blotterOrderToggleModeThunk(orderId: string, mode: OrderResultMode): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    dispatch(ac.blotterOrderToggleMode(orderId, mode));

    if (mode === 'Edit') {
      const order = sl.getBlotterOrderById(state, orderId);
      const editedOrder = { ...order, mode: 'Edit' } as OrderBlotterEntry;
      dispatch(ac.blotterEditedOrderCreateEntry(orderId, editedOrder));
    } else {
      dispatch(ac.blotterEditedOrderRemoveEntry(orderId));
    }
  };
}
