import type { SharedTile } from '../../epics/metaSelectors/workspace/saveWorkspaceSelectors';
import type { GridItemPosition, GridItemSize } from './gridLayoutModels';

export type GridLayoutActions = GridItemSizeChange | GridItemDrag | GridItemDragEnd | GridLayoutFullUpdate;

export interface GridItemSizeChange {
  type: 'GRID_ITEM_SIZE_CHANGED';
  gridId: string;
  gridItemId: string;
  size: GridItemSize;
}

export function gridItemSizeChange(gridId: string, gridItemId: string, size: GridItemSize): GridItemSizeChange {
  return {
    type: 'GRID_ITEM_SIZE_CHANGED',
    gridId,
    gridItemId,
    size,
  };
}

export interface GridItemDrag {
  type: 'GRID_ITEM_DRAG';
  gridId: string;
  gridItemId: string;
  position: GridItemPosition;
}

export function gridItemDrag(gridId: string, gridItemId: string, position: GridItemPosition): GridItemDrag {
  return { type: 'GRID_ITEM_DRAG', gridId, gridItemId, position };
}

export interface GridItemDragEnd {
  type: 'GRID_ITEM_DRAG_END';
  gridId: string;
}

export function gridItemDragEnd(gridId: string): GridItemDragEnd {
  return { type: 'GRID_ITEM_DRAG_END', gridId };
}

export interface GridLayoutFullUpdate {
  type: 'GRID_LAYOUT_FULL_UPDATE';
  gridId: string;
  tiles: SharedTile[];
}

export function gridLayoutFullupdate(gridId: string, tiles: SharedTile[]): GridLayoutFullUpdate {
  return { type: 'GRID_LAYOUT_FULL_UPDATE', gridId, tiles };
}
