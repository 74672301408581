import type { Action } from 'state/actions';
import { type Patcher, composeLeftPatcher } from 'utils/stateMap';
import type { GridState } from '../gridLayoutModels';
import { addGridItem } from './patchers/addGridItem';
import { dragGridItem, setDraggingStatus, submitDraggingLayout } from './patchers/dragGridItem';
import { gridItemSizeChanged } from './patchers/gridItemSizeChange';
import { removeGridItem } from './patchers/removeGridItem';
import { restoreGridItem } from './patchers/restoreGridItem';
import { stripTrailingEmptyColumns } from './patchers/stripTrailingEmptyColumns';
import { updateAddTilePositions } from './patchers/updateAddTilePositions';

export const gridStatePatcher = (action: Action): Patcher<GridState> => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
    case 'CLIENTWORKSPACE_TILE_REOPENED':
      return composeLeftPatcher(addGridItem(action), updateAddTilePositions);

    case 'CLIENTWORKSPACE_TILE_RESTORED':
      return composeLeftPatcher(restoreGridItem(action.tileId, action.savedTile), updateAddTilePositions);

    case 'CLIENTWORKSPACE_TILE_DELETED':
      return composeLeftPatcher(removeGridItem(action), stripTrailingEmptyColumns, updateAddTilePositions);

    case 'GRID_ITEM_SIZE_CHANGED':
      return composeLeftPatcher(gridItemSizeChanged(action), stripTrailingEmptyColumns, updateAddTilePositions);

    case 'GRID_ITEM_DRAG':
      return composeLeftPatcher(setDraggingStatus(action.gridItemId), dragGridItem(action.position));

    case 'GRID_ITEM_DRAG_END':
      return composeLeftPatcher(submitDraggingLayout, stripTrailingEmptyColumns, updateAddTilePositions);

    default:
      return () => null;
  }
};
