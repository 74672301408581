import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import type { InstrumentChoice } from 'state/referenceData/referenceDataModel';
import { addKey, removeKey, updateKey } from 'utils/stateMap';
import type { GridIdleState, GridItemPosition, GridLayout, GridStateCollection } from '../gridLayoutModels';
import { gridStatePatcher } from './gridStatePatcher';
import { updateFullGridLayout } from './patchers/updateFullGridLayout';

export const DEFAULT_LAYOUT: GridLayout = {
  columns: [[]],
  gridItemSizes: {},
  gridItemPositions: {},
};

const DEFAULT_ADD_TILE_POSITIONS: readonly GridItemPosition[] = [
  {
    top: 0,
    left: 0,
  },
];

export const DEFAULT_GRID_STATE: GridIdleState = {
  draggingStatus: 'IDLE',
  gridLayout: DEFAULT_LAYOUT,
  addTilePositions: DEFAULT_ADD_TILE_POSITIONS,
};

const DEFAULT_GRID_COLLECTION: GridStateCollection = {};

export const GRID_LAYOUT_AUTHORIZED_INSTRUMENTS: ReadonlyArray<InstrumentChoice | 'Order'> = [
  'Cash',
  'Option',
  'AmericanForward',
  'Swap',
  'TargetAccumulator',
  'ForwardAccumulator',
  'SmartRfs',
];

export const gridCollectionReducer: Reducer<GridStateCollection> = (currentGridLayoutCollection, action: Action) => {
  const gridLayoutCollection = currentGridLayoutCollection ?? DEFAULT_GRID_COLLECTION;

  switch (action.type) {
    case 'CLIENTWORKSPACE_TAB_ADDED':
      return addKey(gridLayoutCollection, action.tabId, DEFAULT_GRID_STATE);

    case 'CLIENTWORKSPACE_TAB_REMOVED':
      return removeKey(gridLayoutCollection, action.tabId);

    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
    case 'CLIENTWORKSPACE_TILE_REOPENED':
      if (GRID_LAYOUT_AUTHORIZED_INSTRUMENTS.includes(action.instrument)) {
        return updateKey(gridLayoutCollection, action.clientWorkspaceId, gridStatePatcher(action));
      }

      break;

    case 'CLIENTWORKSPACE_TILE_RESTORED': {
      const { instrument, productName } = action.savedTile;

      const instrumentChoice: InstrumentChoice =
        instrument !== 'Accumulator'
          ? instrument
          : productName === 'FxForwardAccumulator'
            ? 'ForwardAccumulator'
            : 'TargetAccumulator';

      if (GRID_LAYOUT_AUTHORIZED_INSTRUMENTS.includes(instrumentChoice)) {
        return updateKey(gridLayoutCollection, action.clientWorkspaceId, gridStatePatcher(action));
      }

      break;
    }

    case 'CLIENTWORKSPACE_TILE_DELETED':
      if (gridLayoutCollection[action.clientWorkspaceId]?.gridLayout.gridItemPositions[action.tileId] === undefined) {
        return gridLayoutCollection;
      }

      return updateKey(gridLayoutCollection, action.clientWorkspaceId, gridStatePatcher(action));

    case 'GRID_ITEM_SIZE_CHANGED':
    case 'GRID_ITEM_DRAG':
    case 'GRID_ITEM_DRAG_END':
      return updateKey(gridLayoutCollection, action.gridId, gridStatePatcher(action));

    case 'GRID_LAYOUT_FULL_UPDATE':
      return updateKey(gridLayoutCollection, action.gridId, updateFullGridLayout(action.tiles));
  }

  return gridLayoutCollection;
};
