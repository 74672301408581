import { QuoteIdContext } from 'components/contexts';
import { useCallback, useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import type { DispatchWithThunkExt } from 'state';
import { gridItemSizeChangeThunk } from 'state/gridLayout/gridLayoutThunks';
import { useElementSize } from './useElementSize';

export function TileSizeMonitor({ children }: React.PropsWithChildren<unknown>) {
  const quoteId = useContext(QuoteIdContext);
  const dispatch: DispatchWithThunkExt = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const onResize = useCallback(
    (size: DOMRectReadOnly) => dispatch(gridItemSizeChangeThunk(quoteId, size)),
    [dispatch, quoteId],
  );

  useElementSize(containerRef, onResize);

  return <div ref={containerRef}>{children}</div>;
}

TileSizeMonitor.displayName = 'TileSizeMonitor';
