import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import type { SaveState } from '..';

const defaultSaveState = 'READY';

export const saveStateReducer: Reducer<SaveState> = (
  state: SaveState = defaultSaveState,
  action: Action,
): SaveState => {
  switch (action.type) {
    case 'SAVE_WORKSPACE_REQUESTED':
      return 'PENDING';

    case 'SAVE_WORKSPACE_DONE':
      return action.success ? 'SUCCESS' : 'FAIL';

    case 'SAVE_WORKSPACE_READY':
      return 'READY';

    default:
      return state;
  }
};
