import { Box, HStack, Spacer, VStack } from '@sgme/ui';
import { type MouseEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { StopLossOrderStatus } from '../../state/fxOrderBulks/fxOrderBulkModel';
import {
  bulkExecutionStatus,
  bulkOrderBdrIs,
  bulkOrderIds,
  isReadOnlyStopLoss,
} from '../../state/fxOrderBulks/fxOrderBulksSelectors';
import { loadStopLossOrdersThunk } from '../../state/fxOrderBulks/thunks/loadStopLossOrdersThunk';
import { submitStopLossOrdersThunk } from '../../state/fxOrderBulks/thunks/submitStopLossOrdersThunk';
import { IntlComponentBoundary } from '../../utils/i18n/IntlComponentBoundary';
import de from '../BulkOrder/locales/de.json';
import en from '../BulkOrder/locales/en.json';
import fr from '../BulkOrder/locales/fr.json';
import { FileImportModal, type ImportResult } from '../Modals/FileImport';
import { BdrSection } from './BdrSection';
import { OrderTable } from './OrderTable';
import { getSavedBulkOrderCompactView, saveBulkOrderCompactView } from './preferences';

const messagesMap = { en, fr, de };

export const BulkOrder = () => {
  const dispatch = useDispatch();

  const allBulkOrderIds = useSelector(bulkOrderIds);
  const allBulkOrderBdrIds = useSelector(bulkOrderBdrIs);
  const executionStatus = useSelector(bulkExecutionStatus);
  const isReadOnly = useSelector(isReadOnlyStopLoss);

  const [isFileImportModelOpen, setIsFileImportModelOpen] = useState(false);
  const [isCompactTable, setIsCompactTable] = useState(getSavedBulkOrderCompactView());

  const canExecute = !isReadOnly && allBulkOrderIds.length > 0 && executionStatus === StopLossOrderStatus.VALID;

  const onOpenImportModal = (event: MouseEvent<HTMLButtonElement>) => {
    // avoid to take the focus
    event.currentTarget.blur();

    setIsFileImportModelOpen(true);
  };

  const onImportStopLossOrders = async (file: File): Promise<ImportResult> => {
    setIsFileImportModelOpen(false);

    const content = await file.text();

    dispatch(loadStopLossOrdersThunk(content));

    return 'SUCCESS';
  };

  const onExecute = (event: MouseEvent<HTMLButtonElement>) => {
    // avoid to take the focus
    event.currentTarget.blur();

    dispatch(submitStopLossOrdersThunk());
  };

  const onCompactViewModeChanged = (value: boolean) => {
    setIsCompactTable(value);
    saveBulkOrderCompactView(value);
  };

  return (
    <IntlComponentBoundary messagesMap={messagesMap}>
      <VStack fullWidth fullHeight className="bg-lvl2">
        <HStack flex="min" gap="12px" margin="24px" className="mb-3">
          <h3 className="col-6">
            <FormattedMessage id="title" />
          </h3>

          <Spacer />

          <HStack className="form-check form-check-md form-check-valid form-switch">
            <input
              className="form-check-input me-8px"
              type="checkbox"
              checked={isCompactTable}
              onChange={(event) => onCompactViewModeChanged(event.target.checked)}
              data-e2e="compact-view-checkbox"
            />

            <label htmlFor=":r1v:" className="form-check-label mb-0 cursor-pointer">
              <FormattedMessage id="label.compactView" />
            </label>
          </HStack>

          <button
            type="button"
            className={`me-2 btn btn-md btn-icon-start ${!canExecute ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={onOpenImportModal}
            data-e2e="import-button"
          >
            <span className="icon">attach_file</span>
            <FormattedMessage id="button.importFile" />
          </button>

          <button
            type="button"
            className={`btn btn-md btn-icon-start ${canExecute ? 'btn-primary' : 'btn-outline-primary'}`}
            disabled={!canExecute}
            onClick={onExecute}
            data-e2e="execute-button"
          >
            <span className="icon">done_all</span>
            <FormattedMessage id="button.execute" />
          </button>
        </HStack>

        <Box flex="expand" className="overflow-y-auto mb-5 pt-0" padding="24px">
          {allBulkOrderIds.length > 0 ? (
            isCompactTable ? (
              <OrderTable orderIds={allBulkOrderIds} />
            ) : (
              allBulkOrderBdrIds.map((bdrId) => <BdrSection key={bdrId} bdrId={bdrId} />)
            )
          ) : (
            <div data-e2e="no-content">
              <FormattedMessage id="label.noContent" />
            </div>
          )}
        </Box>
      </VStack>

      <FileImportModal
        isOpen={isFileImportModelOpen}
        instrument="BulkStopLoss"
        close={() => setIsFileImportModelOpen(false)}
        onImport={onImportStopLossOrders}
      />
    </IntlComponentBoundary>
  );
};
