import { FormattedMessage } from 'react-intl';
import type { SaveState } from 'state/clientWorkspace';
import { MenuButton } from './MenuButton';
import { connectSaveWorkspaceButton } from './connect';

export interface SaveWorkspaceButtonProps {
  saveWorkspaceState: SaveState;
  activeTabId: string;
  isTabShared: boolean;
  saveWorkspace(): void;
  saveSharedTab(tabId: string): void;
}

const icons: Record<SaveState, React.ReactNode> = {
  READY: <i className="icon icon-md">save</i>,
  PENDING: (
    <div className="pt-3 px-1">
      <div className="spinner spinner-md"></div>
    </div>
  ),
  SUCCESS: <i className="icon icon-md text-success py-1">check_circle</i>,
  FAIL: <i className="icon icon-md text-danger py-1">highlight_off</i>,
};
const tooltipIds: Record<string, string | undefined> = {
  READY: 'navMenuButtons.saveWorkspace',
  SHARED_READY: 'navMenuButtons.saveSharedTab',
  PENDING: undefined,
  SHARED_PENDING: undefined,
  SUCCESS: 'navMenuButtons.savedWorkspace',
  SHARED_SUCCESS: 'navMenuButtons.savedSharedTab',
  FAIL: undefined,
  SHARED_FAIL: undefined,
};

export const SaveWorkspaceButtonRaw: React.FunctionComponent<SaveWorkspaceButtonProps> = ({
  saveWorkspace,
  isTabShared,
  activeTabId,
  saveWorkspaceState,
  saveSharedTab,
}) => {
  const tooltipId = tooltipIds[isTabShared ? `SHARED_${saveWorkspaceState}` : saveWorkspaceState];

  const onSave = () => {
    isTabShared ? saveSharedTab(activeTabId) : saveWorkspace();
  };

  return (
    <MenuButton
      color="primary"
      e2eHandle="save-workspace"
      disabled={saveWorkspaceState !== 'READY'}
      onClick={onSave}
      tooltip={tooltipId === undefined ? undefined : <FormattedMessage id={tooltipId} />}
    >
      {icons[saveWorkspaceState]}
    </MenuButton>
  );
};

export const SaveWorkspaceButton = connectSaveWorkspaceButton(SaveWorkspaceButtonRaw);
