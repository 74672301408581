import type { AlertLevel, ValidationResult } from 'components/Form/Inputs/typings';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { FormDomain } from 'state/referenceData/referenceDataModel';
import type { Selectors } from 'state/selectors';
import type { ValueKeys } from 'state/share/productModel';
import type { MapDispatchToPropsHOF } from 'typings/redux-utils';

export interface FieldTooltipConnectOwnProps<I extends FormDomain> {
  quoteId: string;
  instrument: I;
  field: ValueKeys<I>;
}

export interface FieldNoTooltipConnectStateProps {
  alertLevel: undefined;
}

export interface FieldTooltipConnectStateProps {
  alertLevel: AlertLevel;
  messageId: string;
  seen: boolean;
}

export interface FieldTooltipConnectDispatchProps {
  setValidationState(result: ValidationResult): void;
  onSeen(): void;
}

export type FieldTooltipConnectSelectorsKeys = 'getFieldValidationState';
export type FieldTooltipConnectSelectors = Pick<Selectors, FieldTooltipConnectSelectorsKeys>;

export const mapStateToPropsFieldTooltip =
  (sl: FieldTooltipConnectSelectors) =>
  <I extends FormDomain>(
    state: AppState,
    { instrument, field, quoteId }: FieldTooltipConnectOwnProps<I>,
  ): FieldTooltipConnectStateProps | FieldNoTooltipConnectStateProps => {
    return sl.getFieldValidationState(state, instrument, field, quoteId);
  };

export type FieldTooltipConnectActionCreatorsKeys =
  | 'fieldTooltipSeen'
  | 'localFieldValidationSet'
  | 'localFieldValidationClear';
export type FieldTooltipConnectActionCreators = Pick<ActionCreators, FieldTooltipConnectActionCreatorsKeys>;

export const mapDispatchToPropsFieldTooltip: MapDispatchToPropsHOF<
  FieldTooltipConnectDispatchProps,
  FieldTooltipConnectOwnProps<FormDomain>,
  FieldTooltipConnectActionCreators
> =
  (ac) =>
  (dispatch, { instrument, field, quoteId }): FieldTooltipConnectDispatchProps => ({
    onSeen: () => dispatch(ac.fieldTooltipSeen(instrument, field, quoteId)),
    setValidationState(result) {
      dispatch(
        result.status === 'valid'
          ? ac.localFieldValidationClear(instrument, quoteId, field)
          : ac.localFieldValidationSet(instrument, quoteId, field, result.status, result.messageId),
      );
    },
  });
