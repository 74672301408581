import type { Reducer } from '@reduxjs/toolkit';
import type { Action } from 'state/actions';
import type { SharedTile } from '../../../epics/metaSelectors/workspace/saveWorkspaceSelectors';

export const clientWorkspaceUnauthorizedTileReducer: Reducer<Record<string, SharedTile>> = (
  unauthorizedTiles,
  action: Action,
) => {
  switch (action.type) {
    case 'UNAUTHORIZED_SHARED_TILE_UPSERTED':
      return {
        ...unauthorizedTiles,
        [action.tileId]: action.tile,
      };

    default:
      return unauthorizedTiles ?? {};
  }
};
