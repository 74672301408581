import { type MouseEvent as ReactMouseEvent, type ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import type { GridItemPosition, GridItemSize } from 'state/gridLayout/gridLayoutModels';
import { positiveOrZeroPosition } from 'state/gridLayout/positivePositions';
import styled, { type CSSProperties } from 'styled-components';
import { COLUMN_WIDTH } from 'styles/constants';
import { logger } from '../../logging/logger';
import { useAppSelector } from '../../state';
import { isInstrumentAuthorized } from '../../state/clientWorkspace';
import { getQuoteInstrument } from '../../state/clientWorkspace/selectors';
import { getProductsAccess } from '../../state/referenceData/referenceDataSelectors';
import { getProductName } from '../../state/sharedSelectors';
import { UnauthorizedInstrument } from './UnauthorizedItem';

interface GridItemProps {
  gridItemId: string;
  position: GridItemPosition;
  size: GridItemSize;
  zIndex?: number;
  noDragSelector?: string;
  children: ReactNode;

  onDrag(gridItemId: string, position: GridItemPosition): void;

  onDragEnd(): void;
}

export const GridItem = (props: GridItemProps) => {
  const { gridItemId, size, zIndex = 0, children } = props;

  // const tile = useAppSelector((state) => getTileState(state, gridItemId));
  const productsAccess = useAppSelector(getProductsAccess);
  const productName = useAppSelector((state) => getProductName(state, gridItemId));
  const instrument = useAppSelector((state) => getQuoteInstrument(state, gridItemId));

  // TODO: remove the old predicate
  // const isAuthorized = isProductAuthorized(productsAccess, productName);
  const isAuthorized = isInstrumentAuthorized(productsAccess, instrument);

  const [position, setPosition] = useState<GridItemPosition>(props.position);

  const originalPosition = useRef<GridItemPosition>(props.position);

  const originalCenterOfMass = useRef<GridItemPosition>({
    top: props.position.top + Math.floor(props.size.height / 2),
    left: props.position.left + Math.floor(COLUMN_WIDTH / 2),
  });

  const [isMouseTracking, setIsMouseTracking] = useState(false);

  // TODO: try to do a better job
  // isDragging is like isMouseTracking
  // it is to avoid callback dependencies with a state property
  // because the callbacks are used as window listeners
  const isDragging = useRef(false);

  const isMouseDown = useRef(false);
  const offsetPosition = useRef<GridItemPosition | undefined>(undefined);

  // subscribe / unsuscribe to windows mouse move / up
  useEffect(() => {
    const savedIsMouseTracking = isMouseTracking;

    if (savedIsMouseTracking) {
      window.addEventListener('mousemove', onMouseMove, { capture: true });
      window.addEventListener('mouseup', onDragEnd, { capture: true });
    }

    return () => {
      if (savedIsMouseTracking) {
        isDragging.current = false;
        window.removeEventListener('mousemove', onMouseMove, true);
        window.removeEventListener('mouseup', onDragEnd, true);
      }
    };
  }, [isMouseTracking]);

  // after dragging, set the position of the item from the props
  useEffect(() => {
    if (isMouseTracking) {
      return;
    }

    setPosition(props.position);
    originalPosition.current = props.position;

    originalCenterOfMass.current = {
      top: props.position.top + Math.floor(props.size.height / 2),
      left: props.position.left + Math.floor(COLUMN_WIDTH / 2),
    };
  }, [isMouseTracking, props.position, props.size]);

  // track the drag start
  const onMouseDown = useCallback(
    (event: ReactMouseEvent) => {
      // ignore click if left button is not pressed
      if (event.buttons % 2 === 0) {
        return;
      }

      // ignore mousedown event if mousedown is pending
      // most likely to happen if other button is pressed during move
      if (isMouseDown.current === true) {
        return;
      }

      if (props.noDragSelector !== undefined) {
        // from target dom node (lowest dom node at mousedown coordinates)
        // to current target dom node (this component dom node)
        // depending on node tag and class, mousedown may not trigger drag
        let target = event.target as HTMLElement | null;

        while (target !== null && target !== event.currentTarget) {
          if (target.matches(props.noDragSelector)) {
            return;
          }

          target = target.parentElement;
        }
      }

      // consider mousedown a valid drag start
      startDragging(event);
    },
    [props.noDragSelector],
  );

  const startDragging = useCallback((event: ReactMouseEvent<any>) => {
    // avoid event beeing propagated to children nodes
    event.stopPropagation();

    // bind all events
    setIsMouseTracking(true);

    // registers initial position as offset
    offsetPosition.current = { top: event.clientY, left: event.clientX };

    // don't update draggingStatus to true yet, it's better UX
    isMouseDown.current = true;
  }, []);

  const onMouseMove = useCallback((event: MouseEvent) => {
    // bound mousemove with incoherent state safety
    // might be deleted if warn is never fired
    if (isMouseDown.current === false) {
      setIsMouseTracking(false);
      logger.logWarning('mousemove bound on window without corresponding toggle registered');
      return;
    }

    // bound mousemove with incoherent button press
    // if left click is not pressed
    // mouseup event have been missed
    // happens when alt-tabing out of the browser during drag and drop
    if (event.buttons % 2 === 0) {
      return onDragEnd(event);
    }

    return drag(event);
  }, []);

  const drag = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (offsetPosition.current === undefined) {
        logger.logWarning('[GridItem] offsetPosition should be set on mousedown event');
        offsetPosition.current = { top: 0, left: 0 };
      }

      const deltaPosition: GridItemPosition = {
        top: event.clientY - offsetPosition.current.top,
        left: event.clientX - offsetPosition.current.left,
      };

      // if component is not considered dragging, should we update draggingStatus
      if (isDragging.current === false) {
        // if Manhattan distance is below threshold, don't consider component as dragging
        if (Math.abs(deltaPosition.top) + Math.abs(deltaPosition.left) <= SENSITIVITY_THRESHOLD_PIXEL) {
          return;
        }
      }

      // update positions
      const centerOfMass = {
        top: originalCenterOfMass.current.top + deltaPosition.top,
        left: originalCenterOfMass.current.left + deltaPosition.left,
      };

      setPosition({
        top: originalPosition.current.top + deltaPosition.top,
        left: originalPosition.current.left + deltaPosition.left,
      });

      setIsMouseTracking(true);
      isDragging.current = true;

      // forward update to prop callback
      props.onDrag(props.gridItemId, positiveOrZeroPosition(centerOfMass));
    },
    [props.onDrag, props.gridItemId],
  );

  const onDragEnd = useCallback(
    (event: Event) => {
      event.preventDefault();
      event.stopPropagation();

      setIsMouseTracking(false);

      isMouseDown.current = false;

      if (isDragging.current === true) {
        setIsMouseTracking(false);
        isDragging.current = false;
        props.onDragEnd();
      }
    },
    [props.onDragEnd],
  );

  const style: CSSProperties = {
    ...positiveOrZeroPosition(position),
    zIndex: isMouseTracking === true ? 500 + zIndex : undefined,
  };

  return (
    <GridItemContainer isDragging={isDragging.current} style={style} onMouseDown={onMouseDown}>
      {isAuthorized ? children : <UnauthorizedInstrument size={size} productName={productName} />}
    </GridItemContainer>
  );
};

const SENSITIVITY_THRESHOLD_PIXEL = 20;

export const GRID_ITEM_CONTAINER_CLASS_NAME = 'position-absolute d-flex flex-column align-items-center';

const GridItemContainer = styled.div.attrs<{ isDragging?: boolean }>({
  className: GRID_ITEM_CONTAINER_CLASS_NAME,
})`
  ${({ isDragging }: { isDragging?: boolean }) => (isDragging === true ? '' : 'transition: all 120ms ease-in-out;')}
`;
