import type { OnCatchActionCreator } from 'bootstrap/reduxMiddlewares/tryCatchMiddleware';
import { createAppCrashedAction } from 'state/globalError/globalErrorActions';
import type { AppState } from 'state/model';
import { logger } from '../../logging/logger';

export const crashHandler: OnCatchActionCreator<AppState> = (err, action) => {
  let source: any;
  if (err.stack !== undefined) {
    source = err.stack;
  } else {
    try {
      source = JSON.stringify(err);
    } catch (e) {
      source = '';
    }
  }
  logger.logError('app crashed with {source}', JSON.stringify(source));
  return createAppCrashedAction(action.type || action.name, source);
};
