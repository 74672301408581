import { Suspense, useCallback, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { lazyWithRetry } from '../share/lazy';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import { connectLiveBlotterPanel } from './connect';
import { BlotterErrorIndicator } from './LiveBlotterErrorIndicator';
import type { BlotterTab } from 'state/blotter/blotterModel';
import { TooltipContainerRef } from 'components/contexts/TooltipContainer';
import en from './locales/en.json';
import fr from './locales/fr.json';

const messagesMap = { en, fr };

export interface LiveBlotterPanelProps {
  isOpen: boolean;
  height: number;
  activeTab: BlotterTab;
  onPanelHeightChanged(height: number): void;
}

const BlotterContent = lazyWithRetry(() => import('./BlotterContent'));
const LiveBlotterTab = lazyWithRetry(() => import('./LiveBlotterTab'));

interface BlotterPanelContentProps {
  isOpen: boolean;
}

const BlotterContainer = styled.div.attrs({
  className: 'd-flex flex-column bg-primary-alt',
})`
  z-index: 10;
`;

const BlotterHeader = styled.div.attrs({
  className: 'text-center border-top',
})`
  min-height: 10px;
  cursor: row-resize;
`;

const BlotterPanelContent = styled.div<BlotterPanelContentProps>`
  ${(props) => (props.isOpen ? 'flex-grow: 1;' : '')}
  height: ${(props) => (props.isOpen ? 'inherit' : 0)};
`;

const DragHandle = styled.i.attrs({
  className: 'icon icon-sm',
  children: 'drag_handle',
})`
  cursor: row-resize !important;
`;

function LiveBlotterPanelRaw({
  isOpen,
  activeTab,
  height: initialHeight,
  onPanelHeightChanged,
}: LiveBlotterPanelProps) {
  const [offsetPosition, setOffsetPosition] = useState(0);
  const [height, setHeight] = useState(initialHeight);
  const [isResizing, setIsResizing] = useState(false);

  const onMouseUp = useCallback(
    (event: MouseEvent) => {
      if (isResizing) {
        event.stopPropagation();
        event.preventDefault();
        setIsResizing(false);
        onPanelHeightChanged(height);
      }
    },
    [height, isResizing, onPanelHeightChanged],
  );

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (isResizing) {
        event.stopPropagation();
        event.preventDefault();
        if (event.buttons !== 1) {
          onMouseUp(event);
          return;
        }
        const delta = offsetPosition - event.clientY;
        const maxHeight = window.innerHeight - 200;
        setOffsetPosition(event.clientY);
        setHeight(Math.max(Math.min(height + delta, maxHeight), 200));
      }
    },
    [height, isResizing, offsetPosition, onMouseUp],
  );

  const onMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (isEventOnMatchingTarget(event, '[data-no-resize]')) {
        return;
      }
      event.stopPropagation();
      event.preventDefault();
      setOffsetPosition(event.clientY);
      setIsResizing(true);
    },
    [],
  );

  useLayoutEffect(() => {
    window.addEventListener('mousemove', onMouseMove, { capture: true });
    window.addEventListener('mouseup', onMouseUp, { capture: true });
    return () => {
      window.removeEventListener('mousemove', onMouseMove, { capture: true });
      window.removeEventListener('mouseup', onMouseUp, { capture: true });
    };
  }, [onMouseMove, onMouseUp]);

  return (
    <IntlComponentBoundary messagesMap={messagesMap}>
      <TooltipContainerRef>
        {(ref) => (
          <BlotterContainer ref={ref} style={getContainerStyle(isOpen, height)} data-e2e="blotter">
            {isOpen && (
              <BlotterHeader onMouseDown={onMouseDown}>
                <span className="position-absolute">
                  <DragHandle /> <BlotterErrorIndicator />
                </span>
              </BlotterHeader>
            )}
            <BlotterPanelContent className="d-flex flex-column overflow-hidden" isOpen={isOpen}>
              <Suspense
                fallback={
                  <div className="h-100 d-flex justify-content-around align-items-center">
                    <div className="spinner spinner-xl"/>
                  </div>
                }
              >
                {isOpen && (
                  <>
                    <LiveBlotterTab />
                    {/* key property added to blotter content to ensure ag grid component is
                              regenerated when switching tabs and ag grid autoGroupColumnDef property
                              is correctly set */}
                    <BlotterContent key={activeTab} />
                  </>
                )}
              </Suspense>
            </BlotterPanelContent>
          </BlotterContainer>
        )}
      </TooltipContainerRef>
    </IntlComponentBoundary>
  );
}

function isEventOnMatchingTarget(event: React.SyntheticEvent<HTMLElement>, selector: string) {
  let target = event.target as HTMLElement | null;
  while (target !== null && target !== event.currentTarget) {
    if (target.matches(selector)) {
      return true;
    }
    target = target.parentElement;
  }
  return false;
}

const getContainerStyle = (isOpen: boolean, height: number): React.CSSProperties => ({
  height: isOpen ? `${height}px` : undefined,
});

export const LiveBlotterPanel = connectLiveBlotterPanel(LiveBlotterPanelRaw);
