import { QuoteIdProvider, WorkspaceIdProvider } from 'components/contexts';
import { DraggingProvider } from 'components/contexts/Dragging';
import type { GridItemPosition, GridItemSize } from 'state/gridLayout/gridLayoutModels';
import type { Collection } from 'typings/utils';
import { TileRoot } from '../GenericTile/TileRoot';
import { GridDraggingPlaceholder } from './GridDraggingPlaceholder';
import { GridItem } from './GridItem';
import { NewTile } from './NewTile';
import { connectGridContainer } from './connect';

interface GridContainerOwnProps {
  tabId: string;
}

interface IGridContainerStateProps {
  gridItemPositions: Collection<GridItemPosition>;
  gridItemSizes: Collection<GridItemSize>;
  style: { width: number };
}

interface IGridContainerIdleStateProps {
  draggingStatus: 'IDLE';
  addTilePositions: readonly GridItemPosition[];
}

interface IGridContainerDraggingStateProps {
  draggingStatus: 'DRAGGING';
  gridItemPositions: Collection<GridItemPosition>;
  draggingGridItemId: string;
}

type GridContainerStateProps = IGridContainerStateProps &
  (IGridContainerIdleStateProps | IGridContainerDraggingStateProps);

interface IGridContainerDispatchProps {
  onDrag(gridItemId: string, newPosition: GridItemPosition): void;
  onDragEnd(): void;
}

type GridContainerProps = GridContainerOwnProps & GridContainerStateProps & IGridContainerDispatchProps;

const GridContainerRaw: React.FunctionComponent<GridContainerProps> = ({
  tabId,
  gridItemPositions,
  gridItemSizes,
  onDrag,
  onDragEnd,
  ...props
}) => (
  <WorkspaceIdProvider value={tabId}>
    <div className="position-relative" style={props.style ?? undefined}>
      {Object.entries(gridItemPositions).map(([quoteId, position]) => {
        const size = gridItemSizes[quoteId];
        if (position === undefined || size === undefined) {
          throw new Error(`Position and size for tile ${quoteId} should exist at this stage`);
        }
        return (
          <GridItem
            key={quoteId}
            gridItemId={quoteId}
            position={position}
            size={size}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            noDragSelector="input, textarea, label, select, button, .no-drag, [data-nodrag]"
          >
            <QuoteIdProvider value={quoteId}>
              <DraggingProvider value={props.draggingStatus === 'DRAGGING' && quoteId === props.draggingGridItemId}>
                <TileRoot />
              </DraggingProvider>
            </QuoteIdProvider>
          </GridItem>
        );
      })}
      {props.draggingStatus === 'IDLE' ? (
        props.addTilePositions.map((position) => (
          <NewTile key={JSON.stringify({ ...position, gridId: tabId })} position={position} gridId={tabId} />
        ))
      ) : (
        <GridDraggingPlaceholder gridId={tabId} gridItemId={props.draggingGridItemId} />
      )}
    </div>
  </WorkspaceIdProvider>
);

export const GridContainer = connectGridContainer(GridContainerRaw);
