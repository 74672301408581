import { isDefined, isEmpty } from '@sgme/fp';
import type { GridState } from '../../gridLayoutModels';

export function stripTrailingEmptyColumns({ gridLayout }: GridState): Pick<GridState, 'gridLayout'> {
  let columns = gridLayout.columns;

  while (isDefined(columns[columns.length - 1]) && isEmpty(columns[columns.length - 1])) {
    columns = columns.slice(0, columns.length - 1);
  }

  return { gridLayout: { ...gridLayout, columns } };
}
