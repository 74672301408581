import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { AmericanForwardStoreModelChanges } from './tradeCaptureToBackend';
import type {
  AmericanForwardPropertiesChanged,
  AmericanForwardTileOpenFromBlotterEpic,
  AmericanForwardTileRestoreEpic,
} from '../../../state/fxAmericanForward/actions';

type TradeCapturePatchBuilderFromActionSelectorsKeys =
  | 'getAccumulatorCurrencyPairInput'
  | 'getAccumulatorScheduleItemIds';
export type TradeCapturePatchBuilderFromActionSelectors = Pick<
  Selectors,
  TradeCapturePatchBuilderFromActionSelectorsKeys
>;

type MapStateToActionChanges<TAction> = MapStateToMetadataHOF<
  [string, AmericanForwardStoreModelChanges],
  TAction,
  AppState,
  TradeCapturePatchBuilderFromActionSelectors
>;

export const americanForwardChangedToPatchWith: MapStateToActionChanges<AmericanForwardPropertiesChanged> =
  () => (_state, action) => [action.quoteId, action.patch];

export const americanForwardRestoreToPatchWith: MapStateToMetadataHOF<
  Array<[string, AmericanForwardStoreModelChanges]>,
  AmericanForwardTileRestoreEpic,
  AppState,
  TradeCapturePatchBuilderFromActionSelectors
> = () => (_state, action) =>
  Object.entries(action.tiles).map(([tileId, tile]) => [
    tileId,
    {
      callabilityStart: tile.callabilityStart,
      callabilityStartTenor: tile.callabilityStartTenor,
      currencyPair: tile.currencyPair,
      deliveryDate: tile.deliveryDate,
      deliveryDateTenor: tile.deliveryDateTenor,
      expiryDate: tile.expiryDate,
      expiryDateTenor: tile.expiryDateTenor,
      forwardRate: tile.rate,
      hedgeAmount: tile.hedgeAmount,
      hedgeCurrency: tile.hedgeCurrency,
      hedgeRate: tile.hedgeRate,
      hedgeType: tile.hedgeType,
      marketPlace: tile.marketPlace,
      markupCurrency: tile.markupCurrency,
      notionalAmount: tile.notionalAmount,
      notionalCurrency: tile.notionalCurrency,
      premiumDate: tile.premiumDate,
      premiumDateTenor: tile.premiumDateTenor,
      premiumPaymentAmount: tile.premiumPaymentAmount,
      premiumTypeString: tile.premiumTypeString,
      side: tile.side,
    } as AmericanForwardStoreModelChanges,
  ]);

export function americanForwardTileOpenFromBlotterToPatch({
  tileId,
  tile,
}: AmericanForwardTileOpenFromBlotterEpic): [string, AmericanForwardStoreModelChanges] {
  const patch: AmericanForwardStoreModelChanges = {
    callabilityStart: tile.callabilityStartDate,
    currencyPair: tile.currencyPair,
    deliveryDate: tile.deliveryDate,
    expiryDate: tile.expiryDate,
    forwardRate: tile.forwardRate,
    hedgeAmount: tile.hedgeAmount,
    hedgeCurrency: tile.hedgeCurrency,
    hedgeRate: tile.hedgeRate,
    hedgeType: tile.hedgeType,
    marketPlace: tile.marketPlace,
    notionalAmount: tile.notionalAmount,
    notionalCurrency: tile.notionalCurrency,
    premiumDate: tile.premiumDate,
    premiumPaymentAmount: tile.premiumPaymentAmount,
    premiumTypeString: tile.premiumTypeString,
    side: tile.side,
  };

  return [tileId, patch];
}
