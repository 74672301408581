import { isDefined } from '@sgme/fp';
import type { ActionCreators } from 'state/actions';
import type { SaveState } from 'state/clientWorkspace';
import type { AppState } from 'state/model';
import type { Selectors } from 'state/selectors';
import type { MapDispatchToPropsHOF, MapStateToPropsHOF } from 'typings/redux-utils';

export type SaveWorkspaceButtonConnectOwnProps = {};

export interface SaveWorkspaceButtonConnectStateProps {
  saveWorkspaceState: SaveState;
  activeTabId: string | null;
  isTabShared: boolean;
}

export interface SaveWorkspaceButtonConnectDispatchProps {
  saveWorkspace(): void;
  saveSharedTab(tabId: string): void;
}

// you can whitelist selectors after implementation
export type SaveWorkspaceButtonConnectSelectors = Selectors;
/*
export type SaveWorkspaceButtonConnectSelectorsKeys = 'aSelector'; // example
export type SaveWorkspaceButtonConnectSelectors = Pick<Selectors, SaveWorkspaceButtonConnectSelectorsKeys>;
*/

export const mapStateToPropsSaveWorkspaceButton: MapStateToPropsHOF<
  SaveWorkspaceButtonConnectStateProps,
  SaveWorkspaceButtonConnectOwnProps,
  AppState,
  SaveWorkspaceButtonConnectSelectors
> = (sl) => (state) => {
  const activeTabId = sl.getClientWorkspaceActiveTab(state);

  return {
    saveWorkspaceState: sl.getSaveState(state),
    activeTabId,
    isTabShared: isDefined(activeTabId) && sl.isTabShared(state, activeTabId),
  };
};

// you can whitelist action creators after implementation
export type SaveWorkspaceButtonConnectActionCreators = ActionCreators;
/*
export type SaveWorkspaceButtonConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type SaveWorkspaceButtonConnectActionCreators = Pick<ActionCreators, SaveWorkspaceButtonConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsSaveWorkspaceButton: MapDispatchToPropsHOF<
  SaveWorkspaceButtonConnectDispatchProps,
  SaveWorkspaceButtonConnectOwnProps,
  SaveWorkspaceButtonConnectActionCreators
> = (ac) => (dispatch) => ({
  saveWorkspace: () => dispatch(ac.saveWorkspaceThunk()),
  saveSharedTab: (tabId: string) => dispatch(ac.saveSharedTabThunk(tabId)),
});
