import type { Thunk } from 'state';
import type { InstrumentChoice } from 'state/referenceData/referenceDataModel';
import { COLUMN_WIDTH, defaultHeights } from 'styles/constants';
import type { GridItemPosition } from './gridLayoutModels';

export interface Size {
  height: number;
  width: number;
}

export function gridItemSizeChangeThunk(tileId: string, size: Size): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    if (!sl.isTilePresent(state, tileId)) {
      return;
    }
    const tabId = sl.getClientWorkspaceIdByQuoteId(state, tileId);
    const gridItemSize = sl.getGridItemSize(state, tabId, tileId);
    const roundedSize = {
      height: Math.round(size.height),
      width: Math.round(size.width),
    };
    if (
      (roundedSize.height !== gridItemSize.height || roundedSize.width !== gridItemSize.width) &&
      roundedSize.width >= COLUMN_WIDTH &&
      roundedSize.height !== 0
    ) {
      dispatch(ac.gridItemSizeChange(tabId, tileId, roundedSize));
    }
  };
}
/**
 *
 * @todo avoid no-op reduction by checking if hover is over current tile
 */
export function gridItemDragThunk(gridId: string, gridItemId: string, position: GridItemPosition): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    if (sl.newDragPositionWouldChangePosition(state, gridId, gridItemId, position)) {
      dispatch(ac.gridItemDrag(gridId, gridItemId, position));
    }
  };
}

export function gridItemResetHeightByInstrument(tileId: string, instrument: InstrumentChoice): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    if (!sl.isTilePresent(state, tileId)) {
      return;
    }
    const tabId = sl.getClientWorkspaceIdByQuoteId(state, tileId);
    const gridItemSize = sl.getGridItemSize(state, tabId, tileId);
    const height = defaultHeights[instrument];
    if (height !== gridItemSize.height) {
      dispatch(ac.gridItemSizeChange(tabId, tileId, { width: COLUMN_WIDTH, height }));
    }
  };
}
