import { SizeContext } from 'components/Form/Inputs/SizeContext';
import { TooltipContainerContext } from 'components/contexts/TooltipContainer';
import { type ReactNode, useContext, useState } from 'react';
import { COLUMN_WIDTH, tileContainerPadding as padding } from 'styles/constants';
import { DraggingContext } from '../contexts/Dragging';
import { TileWrapper } from './TileWrapper';

const colsStyles = [0, 1, 2, 3].map((cols) => cols * COLUMN_WIDTH).map((width) => ({ width, padding }));

interface TileContainerColsProps {
  fixedColNumber: true;
  cols: number;
}

interface TileContainerSizeProps {
  fixedColNumber: false;
  width: number;
  height?: number;
}

interface ChildrenProp {
  children: ReactNode;
}

export type TileContainerProps = TileContainerColsProps | TileContainerSizeProps;

export function TileContainer({ children, ...props }: ChildrenProp & TileContainerProps): JSX.Element {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const dragging = useContext(DraggingContext);
  return (
    <TileWrapper>
      <SizeContext.Provider value="sm">
        <div
          style={
            props.fixedColNumber === true
              ? colsStyles[props.cols]
              : { width: props.width, height: props.height, padding }
          }
          className={`d-flex bg-transparent${dragging === true ? ' border-primary shadow p-0' : ''}`}
          ref={setContainer}
        >
          <TooltipContainerContext.Provider value={container}>{children}</TooltipContainerContext.Provider>
        </div>
      </SizeContext.Provider>
    </TileWrapper>
  );
}
