import type { FxApi } from 'api/typings';
import { logger } from 'logging/logger';
import { type EpicMiddleware, createEpicMiddleware } from 'redux-observable';
import type { EffectsExtraArgument, EpicExtraArgument } from 'state';
import { actionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import { selectors } from 'state/selectors';
import { getNewRfsId } from 'utils/rfs';
import { v4 as getNewGuid } from 'uuid';
import { metaSelectors } from './metaSelectors';

function formatNumber(value: number, locale: string) {
  // replace nbsp by real spaces
  return Intl.NumberFormat(locale).format(value).replace(/\s/g, ' ');
}

const effects: EffectsExtraArgument = {
  getNewRfsId,
  getDateNow: () => new Date(),
  checkDomValue(quoteId: string, cssSelector: string, sentValue: number): void {
    const inputValue = document.querySelector<HTMLInputElement>(
      `[data-quote-id="${quoteId}"] + form [data-e2e="${cssSelector}"]`,
    )?.value;

    const locale = document.querySelector<Element & { language?: string }>('sgwt-account-center')?.language;

    const formattedNumber = formatNumber(sentValue, locale ?? 'en');

    if (inputValue !== undefined && !Number.isNaN(sentValue)) {
      if (formattedNumber !== inputValue) {
        throw `DOM input mismatch: expected ${formattedNumber} but was actually ${inputValue}`;
      }
    } else {
      logger.logWarning('Could not check state vs input value {@inputDomDetails}', {
        quoteId,
        cssSelector,
        sentValue,
        formattedNumber,
        inputValue,
        locale,
      });
    }
  },
};

export function makeEpicMiddleware(api: FxApi): EpicMiddleware<any, any, AppState, EpicExtraArgument> {
  return createEpicMiddleware<any, any, AppState, EpicExtraArgument>({
    dependencies: {
      actionCreators,
      selectors,
      metaSelectors,
      getNewGuid,
      api,
      effects,
    },
  });
}
