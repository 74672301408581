import { COLUMN_WIDTH, defaultCashHeight } from 'styles/constants';
import type { GridIdleState, GridItemPosition, GridState } from '../../gridLayoutModels';

export function updateAddTilePositions({ gridLayout }: GridState): Pick<GridIdleState, 'addTilePositions'> {
  const addTilePositions: GridItemPosition[] = [];

  for (const column of gridLayout.columns) {
    const columnIndex = gridLayout.columns.indexOf(column);

    let previousBottom = 0;

    for (const position of column) {
      if (position.top - previousBottom >= defaultCashHeight) {
        const newAddTilePosition: GridItemPosition = {
          top: previousBottom,
          left: columnIndex * COLUMN_WIDTH,
        };

        addTilePositions.push(newAddTilePosition);
      }

      previousBottom = position.bottom;
    }

    addTilePositions.push({
      top: previousBottom,
      left: columnIndex * COLUMN_WIDTH,
    });
  }

  addTilePositions.push({
    top: 0,
    left: gridLayout.columns.length * COLUMN_WIDTH,
  });

  return { addTilePositions };
}
